import { Prec } from '@codemirror/state';
import { brainfuck } from "@/plugins/codemirror/brainfuck/brainfuck";
import { isReadonly, readonly, setReadonly } from "@/plugins/codemirror/readonly/readonly";
import { getPointerHighlight, pointerHighlight, setPointerHighlight } from "@/plugins/codemirror/pointer-highlight/pointer-highlight";
import CodeMirrorEditor from "@/plugins/codemirror/codemirror-editor";
import { ChangedRange, commitChanges, isShowingChanges, setShowingChanges, toPositionWithUncommittedChanges, uncommittedChanges, uncommittedChangesListener, toLineAndCharacter } from '@/plugins/codemirror/uncommitted-changes/uncommitted-changes';

export default class BfCodeEditorViewModel {

    private _cmEditor?: CodeMirrorEditor;
    private _source: string = '';
    private _pointerHighlight: number | undefined;
    private _uncommittedChanges: ChangedRange[] = [];

    public get source(): string {
        return this._source;
    }

    public set source(value: string) {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        this._cmEditor.source = value;
    }

    public get readonly(): boolean {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        return isReadonly(this._cmEditor.view);
    }

    public set readonly(value: boolean) {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        setReadonly(this._cmEditor.view, value);
    }

    public get pointerHighlight(): number | undefined {
        return this._pointerHighlight;
    }

    public set pointerHighlight(index: number | undefined) {
        if (this._pointerHighlight !== index) {
            this._pointerHighlight = index;
            this.updateDisplayedPointerHighlight();
        }
    }

    public get showingChanges(): boolean {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        return isShowingChanges(this._cmEditor.view);
    }

    public set showingChanges(value: boolean) {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        setShowingChanges(this._cmEditor.view, value);
    }

    public get hasUncommittedChanges() {
        return this._uncommittedChanges.length > 0;
    }

    public get codeLength() {
        return this.source.match(/[+\-<>,.[\]]/g)?.length ?? 0;
    }

    public async initialize(id: string): Promise<void> {
        this._cmEditor = new CodeMirrorEditor(id, "20rem", [
            readonly(),
            Prec.highest(pointerHighlight()),
            uncommittedChanges(),
            uncommittedChangesListener.of(value => {
                this._uncommittedChanges = value;
            }),
            brainfuck(),
        ]);
        this._cmEditor.addChangeListener(source => this._source = source);
    }

    public scrollTo(index: number) {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        const mappedIndex = toPositionWithUncommittedChanges(this._uncommittedChanges, index);
        this._cmEditor.scrollTo(mappedIndex.position);
    }

    public indentSelection(indent: 'more' | 'less') {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        this._cmEditor.indentSelection(indent);
    }

    public commitChanges() {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        if (this.pointerHighlight != null) {
            const newPointerHighlight = toPositionWithUncommittedChanges(this._uncommittedChanges, this.pointerHighlight);
            this.pointerHighlight = newPointerHighlight.position;
        }
        commitChanges(this._cmEditor.view);
        this.updateDisplayedPointerHighlight();
    }

    public getCurrentInstructionWithUncommittedChanges(currentInstruction: number | undefined, near: boolean = false) {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        if (currentInstruction == null) {
            return undefined;
        }
        const position = toPositionWithUncommittedChanges(this._uncommittedChanges, currentInstruction);
        return !near && position.type === 'near' ? undefined : position.position;
    }

    public getCurrentInstructionLineAndCharacterWithUncommittedChanges(currentInstruction: number | undefined) {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        if (currentInstruction == null) {
            return undefined;
        }
        const instrWith = toPositionWithUncommittedChanges(this._uncommittedChanges, currentInstruction);
        const lineAndChar = toLineAndCharacter(this._cmEditor.view, instrWith.position);
        return {
            type: instrWith.type,
            ...lineAndChar,
        };
    }

    private updateDisplayedPointerHighlight() {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        let nextHighlight;
        if (this.pointerHighlight != null) {
            const mappedIndex = toPositionWithUncommittedChanges(this._uncommittedChanges, this.pointerHighlight);
            nextHighlight = mappedIndex.type === 'exact' ? mappedIndex.position : undefined;
        }
        const pointerHighlight = getPointerHighlight(this._cmEditor?.view);
        if (nextHighlight !== pointerHighlight) {
            setPointerHighlight(this._cmEditor.view, nextHighlight);
        }
    }
}
