import { EditorView } from "codemirror";
import { EditorState, StateField, StateEffect, Transaction, Range, Extension } from '@codemirror/state';
import { Decoration } from '@codemirror/view';

export type ReadonlyRange = { from: number, to: number };

const readonlyRangesEffect = StateEffect.define<Range<Decoration>[]>();

const readonlyRangesField = StateField.define({
    create() {
        return Decoration.none;
    },
    update(value, transaction) {
        const clear = transaction.effects.some((effect) => effect.is(readonlyRangesEffect));
        if (clear) {
            value = Decoration.none;
        } else {
            value = value.map(transaction.changes);
        }

        for (const effect of transaction.effects) {
            if (effect.is(readonlyRangesEffect)) {
                value = value.update({ add: effect.value, sort: true });
            }
        }

        return value;
    },
    provide: f => EditorView.decorations.from(f),
});

const readonlyRangesDecoration = Decoration.mark({
    attributes: { class: "readonly-ranges" },
    disallowZeroGapInbetweenEdits: true,
    disallowZeroGapStartEdits: true,
    disallowZeroGapEndEdits: false,
});

const readonlyRangesTheme = EditorView.theme({
    ".readonly-ranges": {
        backgroundColor: "lightgrey",
        color: "grey",
    },
});

const readonlyRangesTransationFilter =  EditorState.transactionFilter.of((tr) => {
    const readonlyRangeSet = tr.startState.field(readonlyRangesField, false);
    if (readonlyRangeSet && tr.docChanged && tr.annotation(Transaction.userEvent)) {
        let block = false;
        tr.changes.iterChangedRanges((chFrom, chTo) => {
            let zeroWidthLeftAdjacent = false;
            let zeroWidthRightAdjacent = false;
            readonlyRangeSet.between(chFrom, chTo, (roFrom, roTo, value) => {
                if (chTo > roFrom && chFrom < roTo) {
                    block = true;
                    return false;
                }
                if (chFrom === chTo) {
                    if (chFrom === roTo) {
                        zeroWidthLeftAdjacent = true;
                    } else if (chTo === roFrom) {
                        zeroWidthRightAdjacent = true;
                    }
                    if ((value.spec.disallowZeroGapInbetweenEdits && zeroWidthLeftAdjacent && zeroWidthRightAdjacent)
                            || (value.spec.disallowZeroGapStartEdits && roFrom === 0 && chTo === roFrom)
                            || (value.spec.disallowZeroGapEndEdits && roTo === tr.startState.doc.length && chFrom == roTo)) {
                        block = true;
                        return false;
                    }
                }
            });
        });
        if (block) {
            return [];
        }
    }
    return tr;
});

export function readonlyRanges(): Extension {
    return [
        readonlyRangesField,
        readonlyRangesTransationFilter,
        readonlyRangesTheme,
    ];
}

export function getReadonlyRanges(view: EditorView): ReadonlyRange[] {
    const readonlyRangeSet = view.state.field(readonlyRangesField, false);
    const result: ReadonlyRange[] = [];
    const it = readonlyRangeSet?.iter();
    while (it?.value) {
        result.push({ from: it.from, to: it.to });
        it.next();
    }
    return result;
}

export function setReadonlyRanges(view: EditorView, ranges: ReadonlyRange[]) {
    const r = ranges.map(({ from, to }) => readonlyRangesDecoration.range(from, to));
    view.dispatch({ effects: readonlyRangesEffect.of(r) });
}
