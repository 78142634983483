import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-170588b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mr-1 output-font" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mr-1 output-font" }
const _hoisted_4 = {
  key: 0,
  class: "text-error no-charachter-data"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "cell-tape d-flex flex-row align-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_bf_text_info = _resolveComponent("bf-text-info")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_bf_pagination = _resolveComponent("bf-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.vm.editDialog,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.vm.editDialog) = $event)),
      width: "auto",
      "min-width": "250",
      "max-width": "400",
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          title: `Edit Cell ${ _ctx.vm.editCell }`
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "ms-auto text-none",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.vm.closeEditDialog()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cancel")
              ]),
              _: 1
            }),
            _createVNode(_component_v_btn, {
              class: "text-none",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.save()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Save")
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_container, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.vm.editDezValue,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vm.editDezValue) = $event)),
                          label: "Dezimal Value",
                          density: "comfortable",
                          "hide-details": "",
                          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.vm.setEditCellValueByDezValue($event.target.value)))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.vm.editHexValue,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vm.editHexValue) = $event)),
                          label: "Hexadezimal Value",
                          density: "comfortable",
                          "hide-details": "",
                          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.vm.setEditCellValueByHexValue($event.target.value)))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_autocomplete, {
                          class: "output-font-input output-font-span",
                          items: _ctx.vm.editCharItems,
                          modelValue: _ctx.vm.editCharDisplayItem,
                          "onUpdate:modelValue": [
                            _cache[4] || (_cache[4] = ($event: any) => ((_ctx.vm.editCharDisplayItem) = $event)),
                            _cache[6] || (_cache[6] = ($event: any) => (_ctx.vm.setEditCellValueByCharItem($event)))
                          ],
                          search: _ctx.vm.editCharSearch,
                          "onUpdate:search": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.vm.editCharSearch) = $event)),
                          label: "Character",
                          "item-title": "search",
                          density: "comfortable",
                          "no-data-text": "Enter a character",
                          "no-filter": "",
                          "hide-details": "",
                          "auto-select-first": "",
                          "return-object": ""
                        }, {
                          selection: _withCtx((data) => [
                            _createElementVNode("span", _hoisted_1, _toDisplayString(data.item.raw.search), 1),
                            (data.item.raw.data && data.item.raw.data.length >= 2)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.item.raw.data, (d, i) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      class: _normalizeClass(['ml-1', 'character-data', i != data.item.raw.index ? 'other-charachter-data' : undefined]),
                                      key: i
                                    }, _toDisplayString(d), 3))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          item: _withCtx((data) => [
                            _createVNode(_component_v_list_item, _mergeProps(data.props, {
                              role: "option",
                              title: undefined
                            }), {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_3, _toDisplayString(data.item.raw.search), 1),
                                    (!data.item.raw.data)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Cannot be printed "))
                                      : _createCommentVNode("", true),
                                    (data.item.raw.data && data.item.raw.data.length >= 2)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.item.raw.data, (d, i) => {
                                            return (_openBlock(), _createElementBlock("span", {
                                              class: _normalizeClass(['ml-1', 'character-data', i != data.item.raw.index ? 'other-charachter-data' : undefined]),
                                              key: i
                                            }, _toDisplayString(d), 3))
                                          }), 128))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1040)
                          ]),
                          _: 1
                        }, 8, ["items", "modelValue", "search"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, {
                          modelValue: _ctx.vm.editSetAsActiveCell,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.vm.editSetAsActiveCell) = $event)),
                          label: "Active Cell",
                          density: "comfortable",
                          disabled: !_ctx.vm.editCanChangeActiveCell,
                          "hide-details": ""
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_sheet, {
      class: "w-100 pa-4 my-2",
      color: "grey-lighten-3",
      rounded: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_label, null, {
          default: _withCtx(() => [
            _createTextVNode("Memory")
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.cellsOnPage, (i) => {
                    return (_openBlock(), _createBlock(_component_v_sheet, {
                      key: i,
                      ref_for: true,
                      ref: _ctx.setCellRef(i - 1),
                      class: "cell",
                      color: _ctx.vm.activeCell === (_ctx.vm.firstDisplayedCell + i - 1) ? 'red-darken-3' : 'grey-lighten-1',
                      rounded: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, { class: "cell-container" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "d-flex justify-space-between align-center" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h5", null, "Cell " + _toDisplayString(_ctx.vm.firstDisplayedCell + i - 1), 1),
                                _createVNode(_component_v_btn, {
                                  class: "ml-2",
                                  icon: "mdi-pencil",
                                  size: "small",
                                  density: "compact",
                                  variant: "text",
                                  disabled: _ctx.vm.readonly,
                                  onClick: ($event: any) => (_ctx.vm.openEditDialog(_ctx.vm.firstDisplayedCell + i - 1)),
                                  rounded: ""
                                }, null, 8, ["disabled", "onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_bf_text_info, {
                                  "model-value": _ctx.cellInfo(i)
                                }, null, 8, ["model-value"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["color"]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "auto",
              class: "memory-text-info"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_bf_text_info, { "model-value": _ctx.currentCellTextInfo }, null, 8, ["model-value"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "d-flex justify-center ga-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  icon: "mdi-vector-point-select",
                  disabled: _ctx.vm.activeCell == null,
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.vm.scrollToActiveCell(_ctx.cellRefs))),
                  rounded: ""
                }, null, 8, ["disabled"]),
                _createVNode(_component_bf_pagination, {
                  modelValue: _ctx.vm.page,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.vm.page) = $event)),
                  "item-count": _ctx.vm.tapeSize,
                  "page-size": _ctx.vm.pageSize,
                  "onUpdate:pageSize": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.vm.pageSize) = $event)),
                  maxPageSize: 1024,
                  onItemsOnPage: _cache[14] || (_cache[14] = ($event: any) => (_ctx.vm.cellsOnPage = $event)),
                  onFirstItemOnPageIndex: _cache[15] || (_cache[15] = ($event: any) => (_ctx.vm.firstDisplayedCell = $event))
                }, null, 8, ["modelValue", "item-count", "page-size"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}