import { ToWorkerMessage, FromWorkerMessage, IntegerArray } from "./brainfuck-worker-controller";
import { mutexCode } from "../synchronization/mutex";
import Sync, { syncCode } from "../synchronization/sync";

declare global {
    /* eslint-disable no-var */
    var __IS_DEBUG__: boolean;
    var __ARRAY_TYPE__: any;
    var __INITIAL_TAPE_SIZE__: number;
    var __RUN__: any;
    var __FUNCS__: any;
    var __START_CODE_POINTER__: number | undefined;
    /* eslint-enable no-var */
}

class StopExecutionError extends Error {
    constructor() {
        super("Stop execution");
    }
}

export class BrainfuckWorker {

    public static readonly MAX_OUTPUT_SIZE = 65536;
    public static readonly MAX_OUTPUT_DATA_SEND_SIZE = 3 * 65536 + 3; // 3 (max. bytes per utf-8 character for a single 16-bit utf-16 character) * 65536 (max. output size) + 3 (max. bytes per incomplete uft-8 charcter)

    private readonly _debug: boolean = __IS_DEBUG__;

    private _tape?: IntegerArray;
    private _tapePointer: number = 0;
    private _output: number[] = [];
    private _input?: IntegerArray;
    private _inputPointer: number = 0;
    private _wait: boolean = false;
    private _skipBreakpoints: boolean = false;
    private _singleStep: boolean = false;
    private _updateSource: boolean = false;
    private _outputLength: number = 0;
    private _executedInstrCountBase: number = 0;
    private _executedInstrCountOffset: number = 0;
    private _fromTime?: number = undefined;
    
    private _sync?: Sync;
    private _syncBuffer?: Int32Array;

    constructor() {
        addEventListener("message", (event) => this.onMessage(event));
    }

    private createArray(length: number): IntegerArray {
        const array = __ARRAY_TYPE__;
        return new array(new SharedArrayBuffer(length * array.BYTES_PER_ELEMENT));
    }

    private onMessage(event: MessageEvent<ToWorkerMessage>) {
        const msg = event.data;
        if (msg.type !== 'sync') {
            return;
        }
        this._sync = newSync(msg.data.syncBuffer);
        this._syncBuffer = this._sync.buffer;
        if (msg.data.initEmptyTape) {
            this._tape = this.createArray(__INITIAL_TAPE_SIZE__);
        } else if (msg.data.initTape) {
            this._tape = msg.data.initTape;
        }
        this.run();
    }

    private run() {
        try {
            this.waitForStart();

            if (this._input == null) {
                throw new Error("Input is not supplied");
            }
            if (this._tape == null) {
                throw new Error("Tape is not supplied");
            }
            
            this.runExecute();
            this.sendData(undefined);
        } catch (e: any) {
            if (!(e instanceof StopExecutionError)) {
                const message = e?.message ?? e?.toString() ?? 'Unexpected error while executing';
                this.sendMessage({ type: 'error', data: { msg: message }});
                console.error(e);
                return;
            }
        }
        if (this._updateSource) {
            this.sendMessage({ type: 'updateSource', data: { wait: this._wait } });
        } else {
            this.sendMessage({ type: 'end' });
        }
    }

    private runExecute() {
        __RUN__
    }

    __FUNCS__: any;

    private sendData(codePointer: number | undefined) {
        this._outputLength += this._output.length;
        if (this._output.length > BrainfuckWorker.MAX_OUTPUT_DATA_SEND_SIZE) {
            this._output = this._output.slice(-BrainfuckWorker.MAX_OUTPUT_DATA_SEND_SIZE);
        }
        const toTime = this._fromTime ? performance.now() : undefined;
        this.sendMessage({
            type: 'data',
            data: {
                output: this._output,
                outputLength: this._outputLength,
                tape: this._tape!,
                tapePointer: this._tapePointer,
                inputPointer: this._inputPointer,
                currentInstruction: codePointer,
                executedInstructions: this._executedInstrCountBase + this._executedInstrCountOffset,
                fromTime: this._fromTime,
                toTime: toTime,
            },
        });
        this._fromTime = toTime;
        this._output = [];
    }

    // Executed instruction count needs to be up to date when calling this function
    private processUpdate(codePointer: number | undefined) {
        const sync = this._sync!;
        sync.mutex.lock();
        if (!sync.update) {
            sync.mutex.unlock();
            return;
        }
        sync.update = false;

        const sendData = sync.dataRequest;
        sync.dataRequest = false;

        let newInput: IntegerArray | undefined = undefined;
        if (sync.updateInput) {
            const inputSize = sync.updatedInputSize;
            newInput = this.createArray(inputSize);
            sync.updatedInputWrittenAtomic = false;
            this.sendMessage({ type: 'request', data: { target: 'input', buffer: newInput } });
            sync.updatedInputSize = 0;
            sync.updateInput = false;
        }

        if (sync.updateInputPointer) {
            this._inputPointer = sync.updatedInputPointer;
            sync.updatedInputPointer = 0;
            sync.updateInputPointer = false;
        }

        if (sync.updateTapeCell) {
            const tapeCellIndex = sync.updateTapeCellIndex;
            this._tape![tapeCellIndex] = sync.updatedTapeCell;
            sync.updatedTapeCell = 0;
            sync.updateTapeCellIndex = 0;
            sync.updateTapeCell = false;
        }

        if (sync.updateTapePointer) {
            this._tapePointer = sync.updatedTapePointer;
            sync.updatedTapePointer = 0;
            sync.updateTapePointer = false;
        }

        let stop = false;
        if (sync.updateSource) {
            stop = true;
            this._updateSource = true;
            sync.updateSource = false;
        }

        switch (sync.action) {
            case 'run':
            case 'restart-run':
            case 'continue':
                this._wait = false;
                this._skipBreakpoints = false;
                this._singleStep = false;
                break;
            case 'continue-without-breakpoints':
                this._wait = false;
                this._skipBreakpoints = true;
                this._singleStep = false;
                break;
            case 'restart-wait':
            case 'single-step':
            case 'pause':
                this._wait = false;
                this._skipBreakpoints = false;
                this._singleStep = true;
                break;
            case 'stop':
                stop = true;
                this._updateSource = false;
                break;
        }
        sync.action = undefined;
        
        sync.mutex.unlock();

        if (newInput) {
            sync.waitForUpdatedInputWritten();
            this._input = newInput;
        }

        if (sendData || stop) {
            this.sendData(codePointer);
        }

        if (stop) {
            throw new StopExecutionError();
        }

        if (!this._debug && this._singleStep) {
            this.wait(codePointer, 0);
        }
    }

    private sendError(codePointer: number, executedInstructionsOffset: number, message: string) {
        this._executedInstrCountOffset = executedInstructionsOffset + 1; // + 1 to count the instruction that failed
        this.sendData(codePointer);
        this.sendMessage({ type: 'error', data: { msg: message }});
        throw new StopExecutionError();
    }

    private sendErrorTapeOverflow(codePointer: number, executedInstructionsOffset: number) {
        this.sendError(codePointer, executedInstructionsOffset, 'Tape overflow');
    }

    private sendErrorTapeUnderflow(codePointer: number, executedInstructionsOffset: number) {
        this.sendError(codePointer, executedInstructionsOffset, 'Tape underflow');
    }

    private sendErrorCellOverflow(codePointer: number, executedInstructionsOffset: number) {
        this.sendError(codePointer, executedInstructionsOffset, 'Cell overflow');
    }

    private sendErrorCellUnderflow(codePointer: number, executedInstructionsOffset: number) {
        this.sendError(codePointer, executedInstructionsOffset, 'Cell underflow');
    }

    private wait(codePointer: number | undefined, executedInstructionsOffset: number) {
        this._executedInstrCountOffset = executedInstructionsOffset;
        this.sendData(codePointer);
        this.sendMessage({ type: 'pause' });

        this._wait = true;
        this._fromTime = undefined;
        while (this._wait) {
            this._sync!.waitForUpdate();
            this.processUpdate(codePointer);
        }
        this._fromTime = performance.now();
    }

    private waitForStart() {
        const startCodePointer = __START_CODE_POINTER__;
        this._wait = true;
        this._fromTime = undefined;
        while (this._wait) {
            this._sync!.waitForUpdate();
            this.processUpdate(startCodePointer);
        }
        this._fromTime = performance.now();
    }

    private sendMessage(message: FromWorkerMessage) {
        postMessage(message);
    }
}

export function getWorkerCode(): string {
    return BrainfuckWorker.toString()
        + StopExecutionError.toString()
        + mutexCode
        + syncCode
        + '(' + (() => new BrainfuckWorker()).toString() + ')()';
}
