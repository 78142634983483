<template>
  <div class="mt-2 d-flex justify-center align-center">
    <v-btn
      class="mx-1"
      density="compact"
      icon="mdi-chevron-left"
      rounded
      :disabled="!hasPervious"
      @click="setPage(innerPage - 1)">
    </v-btn>
    <v-text-field
      class="mx-1 page"
      v-model="pageDisplay"
      label="Page"
      variant="underlined"
      density="compact"
      hide-details
      @change="setPage($event.target.value)"
      @focus="$event.target.select()">
    </v-text-field>
    <v-btn
      class="mx-1"
      density="compact"
      icon="mdi-chevron-right"
      rounded
      :disabled="!hasNext"
      @click="setPage(innerPage + 1)">
    </v-btn>
    <v-text-field
      class="ml-4 mr-1 page-size"
      v-model="pageSizeDisplay"
      label="Page Size"
      variant="underlined"
      density="compact"
      hide-details
      @change="setPageSize($event.target.value)"
      @focus="$event.target.select()">
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'bf-pagination',
  props: {
    modelValue: { type: Number, required: true },
    itemCount: { type: Number, required: true },
    pageSize: { type: Number, default: 100 },
    minPageSize: { type: Number, default: 1 },
    maxPageSize: { type: Number, default: 1000 },
  },
  emits: ['update:modelValue', 'update:pageSize', 'itemsOnPage', 'firstItemOnPageIndex'],
  data() {
    return {
      innerPage: this.modelValue,
      innerPageSize: this.pageSize,
      pageDisplay: '',
      pageSizeDisplay: '',
    }
  },
  computed: {
    pageCount(): number {
      return Math.ceil(this.itemCount / this.innerPageSize);
    },
    hasPervious(): boolean {
      return this.innerPage > 1;
    },
    hasNext(): boolean {
      return this.innerPage < this.pageCount;
    },
  },
  watch: {
    modelValue() {
      this.setPage(this.modelValue);
    },
    itemCount() {
      this.setPage(this.modelValue);
    },
    pageSize() {
      this.setPageSize(this.pageSize);
    },
    minPageSize() {
      this.setPageSize(this.pageSize);
    },
    maxPageSize() {
      this.setPageSize(this.pageSize);
    },
  },
  async beforeMount() {
    this.setPage(this.modelValue);
    this.setPageSize(this.pageSize);
  },
  async mounted() {
    await this.$nextTick();
    await this.$nextTick();
    this.emitItemsOnPageAndFirstItemOnPage();
  },
  methods: {
    setPage(value: string | number) {
      const parsed = typeof value === 'number' ? value : parseInt(value, 10);
      if (isNaN(parsed)) {
        this.updatePageDisplay();
        return;
      }
      this.innerPage = Math.max(1, Math.min(parsed, this.pageCount));
      this.updatePageDisplay();
      this.$emit('update:modelValue', this.innerPage);
      this.emitItemsOnPageAndFirstItemOnPage();
    },
    setPageSize(value: string | number) {
      const parsed = typeof value === 'number' ? value : parseInt(value, 10);
      if (isNaN(parsed)) {
        this.updatePageSizeDisplay();
        return;
      }
      const oldPage = this.innerPage;
      const oldPageSize = this.innerPageSize;
      this.innerPageSize = Math.max(this.minPageSize, Math.min(parsed, this.maxPageSize));
      const newPage = Math.floor((oldPage - 1) * oldPageSize / this.innerPageSize + 1);

      this.$emit('update:pageSize', this.innerPageSize);
      this.updatePageSizeDisplay();
      this.setPage(newPage);
      this.emitItemsOnPageAndFirstItemOnPage();
    },
    emitItemsOnPageAndFirstItemOnPage() {
      const firstItemOnPage = (this.innerPage - 1) * this.innerPageSize;
      this.$emit('itemsOnPage', Math.min(this.innerPageSize, this.itemCount - firstItemOnPage));
      this.$emit('firstItemOnPageIndex', firstItemOnPage);
    },
    updatePageDisplay() {
      this.pageDisplay = this.innerPage + '/' + this.pageCount;
    },
    updatePageSizeDisplay() {
      this.pageSizeDisplay = this.innerPageSize.toString();
    },
  },
})
</script>

<style scoped>
  .page, .page-size {
    width: 80px;
  }
</style>
