import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    ref: "growOnly",
    class: "d-inline-block",
    style: _normalizeStyle({ minWidth: `${_ctx.minWidth}px` })
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}