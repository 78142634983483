import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';

// Vuetify
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import './assets/global.css';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import LayoutDefault from './layouts/layout-default.vue';
import BfMemoryViewer from './components/bf-memory-viewer/bf-memory-viewer.vue';
import BfPagination from './components/bf-pagination/bf-pagination.vue';
import BfCodeEditor from './components/bf-code-editor/bf-code-editor.vue';
import BfTextArea from './components/bf-text-area/bf-text-area.vue';
import BfTextInfo from './components/bf-text-info/bf-text-info.vue';
import BfGrowOnly from './components/bf-grow-only/bf-grow-only.vue';
import router from './router';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
        mdi,
    },
  },
});

createApp(App)
    .use(router)
    .use(vuetify)
    .component('layout-default', LayoutDefault)
    .component('bf-memory-viewer', BfMemoryViewer)
    .component('bf-pagination', BfPagination)
    .component('bf-code-editor', BfCodeEditor)
    .component('bf-text-area', BfTextArea)
    .component('bf-text-info', BfTextInfo)
    .component('bf-grow-only', BfGrowOnly)
    .mount('#app');
