import { EditorView } from "codemirror";
import { StateField, StateEffect, Extension, Facet, RangeSet, MapMode } from '@codemirror/state';
import { Decoration, DecorationSet } from '@codemirror/view';

export const pointerHighlightListener = Facet.define<(newPointerHighlight: number | undefined, oldPointerHighlight: number | undefined) => void>();

const highlightEffect = StateEffect.define<number | undefined>();

const highlightField = StateField.define<number | undefined>({
    create() {
        return undefined;
    },
    update(value, transaction) {
        let newValue = value;
        
        const highlight = transaction.effects.findLast(effect => effect.is(highlightEffect));
        if (highlight) {
            newValue = highlight.value;
        }

        if (newValue == null || newValue < 0 || newValue >= transaction.startState.doc.length) {
            newValue = undefined;
        } else {
            newValue = transaction.changes.mapPos(newValue, 1, MapMode.TrackAfter) ?? undefined;
        }

        if (value !== newValue) {
            const listeners = transaction.startState.facet(pointerHighlightListener);
            for (const listener of listeners) {
                listener(newValue, value);
            }
        }

        return newValue;
    },
    provide: f => EditorView.decorations.from(f, value => {
        return value == null
            ? Decoration.none
            : RangeSet.of([highlightDecoration.range(value, value + 1)]) as DecorationSet;
    }),
});

const highlightDecoration = Decoration.mark({
    attributes: { class: "pointer-position" },
});

const highlightTheme = EditorView.theme({
    ".pointer-position": {
        backgroundColor: "#C62828",
        color: "white",
    },
});

export function pointerHighlight(): Extension {
    return [
        highlightField,
        highlightTheme,
    ];
}

export function getPointerHighlight(view: EditorView): number | undefined {
    return view.state.field(highlightField, false);
}

export function setPointerHighlight(view: EditorView, pointer: number | undefined) {
    view.dispatch({ effects: highlightEffect.of(pointer) });
}
