<template>
  <span ref="growOnly" class="d-inline-block" :style="{ minWidth: `${minWidth}px` }">
    <slot></slot>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'bf-grow-only',
  data() {
    return {
      minWidth: 0 as number,
      resizeObserver: undefined as ResizeObserver | undefined,
    }
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.contentBoxSize?.[0]?.inlineSize;
        if (width) {
          this.minWidth = width;
        }
      }
    });
    const el = this.$refs.growOnly as HTMLElement | undefined;
    if (el) {
      this.resizeObserver?.observe(el);
    }
  },
  unmounted() {
    this.resizeObserver?.disconnect();
  },
})
</script>
