import { db } from '@/utils/indexed-db/db';
import { BfFile, ExecSettings, initFile } from '@/utils/file';

export default class SettingsViewModel {

    public readonly tapeOverflowItems = [
        { title: 'Wrap around to First or Last Cell', value: 'wrap' },
        { title: 'Stay at Current Cell', value: 'ignore' },
        { title: 'Throw an Error', value: 'throw' },
    ];

    public readonly cellOverflowItems = [
        { title: 'Wrap around to Lowest or Highest Value', value: 'wrap' },
        { title: 'Stay at Current Value', value: 'ignore' },
        { title: 'Throw an Error', value: 'throw' },
    ];

    public readonly characterSetItems = [
        { title: 'ASCII (Windows-1252)', value: 'ascii' },
        { title: 'UTF-8', value: 'utf-8' },
        { title: 'UTF-16', value: 'utf-16' },
    ];

    public readonly endOfInputItems = [
        { title: 'Set Cell to 0', value: 'return0' },
        { title: 'Set Cell to -1', value: 'return-1' },
        { title: "Don't Change Cell", value: 'noChange' },
    ];

    public readonly newLinesItems = [
        { title: 'Line Feed', value: 'n' },
        { title: 'Carriage Return Line Feed', value: 'rn' },
        { title: 'Carriage Return', value: 'r' },
    ];

    public fileId?: number;
    public file?: BfFile;

    public saved: boolean = false;
    public error?: Error = undefined;
    public tapeSizeDisplay: string = '';
    public cellSizeDisplay: string = '';
    public cellSizeBitsDisplay: string = '';

    public get settings(): ExecSettings {
        return this.initFile().content.settings.execution;
    }

    public async initialize(fileId: string | string[]): Promise<void> {
        if (Array.isArray(fileId) || Number.isNaN(+fileId)) {
            throw new Error("Id is not a number");
        }
        this.fileId = +fileId;
        const filesStore = await db.files();
        this.file = await filesStore.get(this.fileId);
        this.updateTapeSizeDisplay();
        this.updateCellSizeAndBitsDisplay();
    }

    public setTapeSize(value: string | number) {
        const parsed = typeof value === 'number' ? value : parseInt(value, 10);
        if (isNaN(parsed)) {
            this.updateTapeSizeDisplay();
            return;
        }
        const newValue = Math.max(1, Math.min(parsed, 2**24));
        this.settings.tapeSize = newValue;
        this.updateTapeSizeDisplay();
    }

    public setCellSize(value: string | number) {
        const parsed = typeof value === 'number' ? value : parseInt(value, 10);
        if (isNaN(parsed)) {
            this.updateCellSizeAndBitsDisplay();
            return;
        }
        const newValue = Math.max(1, Math.min(parsed, 2**32));
        this.settings.cellSize = newValue;
        this.updateCellSizeAndBitsDisplay();
    }

    public setCellSizeBits(value: string | number) {
        const parsed = typeof value === 'number' ? value : parseInt(value, 10);
        if (isNaN(parsed)) {
            this.updateCellSizeAndBitsDisplay();
            return;
        }
        const newValue = 2**Math.max(0, Math.min(parsed, 32));
        this.settings.cellSize = newValue;
        this.updateCellSizeAndBitsDisplay();
    }

    public async save() {
        if (!this.file) {
            this.error = new Error("No file opened");
            return;
        }
        try {
            const filesStore = await db.files();
            await filesStore.put(this.file);
            this.saved = true;
        } catch (ex: any) {
            this.error = ex;
        }
    }

    public clearSaved() {
        this.saved = false;
    }

    private updateTapeSizeDisplay() {
        this.tapeSizeDisplay = this.settings.tapeSize.toString();
    }

    private updateCellSizeAndBitsDisplay() {
        this.cellSizeDisplay = this.settings.cellSize.toString();
        const bits = Math.log2(this.settings.cellSize);
        this.cellSizeBitsDisplay = (bits % 1 != 0 ? '≈ ' : '') + Math.round(bits);
    }

    private initFile() {
        return initFile(this.file);
    }
}
