import { EditorView } from "codemirror";
import { StateField, StateEffect, Extension } from '@codemirror/state';

const editableEffect = StateEffect.define<boolean>();

const editableField = StateField.define({
    create() {
        return true;
    },
    update(value, transaction) {
        const effect: StateEffect<boolean> | undefined = transaction.effects.find((effect) => effect.is(editableEffect));
        return effect?.value ?? value;
    },
    provide: f => EditorView.editable.from(f),
});

export function readonly(): Extension {
    return editableField;
}

export function isReadonly(view: EditorView): boolean {
    const editable = view.state.field(editableField, false) ?? true;
    return !editable;
}

export function setReadonly(view: EditorView, value: boolean) {
    view.dispatch({ effects: editableEffect.of(!value) });
}
