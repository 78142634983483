export default class Mutex {

    private static readonly locked = 1;
    private static readonly unlocked = 0;

    private _buffer: Int32Array;
    private _index: number;
    private _lockCount: number = 0;
    private _waitingPromise?: Promise<any>;

    public lockAsync: () => Promise<void>;

    public constructor(buffer: Int32Array, index: number) {
        this._buffer = buffer;
        this._index = index;
        this.lockAsync = typeof Atomics.waitAsync === 'function' ? this.lockAsyncAtomicsWaitAsync : this.lockAsyncSetTimeout;
    }

    public isLockFree(): boolean {
        return Atomics.load(this._buffer, this._index) === Mutex.unlocked;
    }

    public isLockAquiredByMe(): boolean {
        return this._lockCount > 0;
    }

    public lock() {
        while (this._lockCount === 0 && Atomics.compareExchange(this._buffer, this._index, Mutex.unlocked, Mutex.locked) !== Mutex.unlocked) {
            Atomics.wait(this._buffer, this._index, Mutex.locked);
        }
        this._lockCount++;
    }

    public tryLock(): boolean {
        if (this._lockCount === 0 && Atomics.compareExchange(this._buffer, this._index, Mutex.unlocked, Mutex.locked) !== Mutex.unlocked) {
            return false;
        }
        this._lockCount++;
        return true;
    }

    private async lockAsyncAtomicsWaitAsync(): Promise<void> {
        while (this._lockCount === 0 && Atomics.compareExchange(this._buffer, this._index, Mutex.unlocked, Mutex.locked) !== Mutex.unlocked) {
            if (!this._waitingPromise) {
                const wait = Atomics.waitAsync(this._buffer, this._index, Mutex.locked);
                if (wait.async) {
                    this._waitingPromise = wait.value;
                }
            }
            await this._waitingPromise;
            this._waitingPromise = undefined;
        }
        this._lockCount++;
    }

    private async lockAsyncSetTimeout(): Promise<void> {
        while (this._lockCount === 0 && Atomics.compareExchange(this._buffer, this._index, Mutex.unlocked, Mutex.locked) !== Mutex.unlocked) {
            this._waitingPromise ??= new Promise(resolve => setTimeout(resolve, 5));
            await this._waitingPromise;
            this._waitingPromise = undefined;
        }
        this._lockCount++;
    }

    public unlock(): boolean {
        if (this._lockCount === 0) {
            throw new Error("Mutex is in an inconsistent state: Cannot unlock on unlocked mutex.");
        }
        this._lockCount--;
        if (this._lockCount !== 0) {
            return false;
        }
        if (Atomics.compareExchange(this._buffer, this._index, Mutex.locked, Mutex.unlocked) !== Mutex.locked) {
            throw new Error("Mutex is in an inconsistent state: Cannot unlock on unlocked mutex.");
        }
        Atomics.notify(this._buffer, this._index, 1);
        return true;
    }

    public unlockAll() {
        if (this._lockCount === 0) {
            return;
        }
        this._lockCount = 0;
        if (Atomics.compareExchange(this._buffer, this._index, Mutex.locked, Mutex.unlocked) !== Mutex.locked) {
            throw new Error("Mutex is in an inconsistent state: Cannot unlock on unlocked mutex.");
        }
        Atomics.notify(this._buffer, this._index, 1);
    }
}

declare global {
    // eslint-disable-next-line no-var
    var newMutex: (buffer: Int32Array, index: number) => Mutex;
}

globalThis.newMutex = (buffer: Int32Array, index: number) => new Mutex(buffer, index);

export const mutexCode = Mutex.toString() + `newMutex=${newMutex};`;
