import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        density: "compact",
        class: "position-sticky"
      }, {
        prepend: _withCtx(() => [
          (_ctx.hasBackButton)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                icon: "mdi-arrow-left",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back'))),
                rounded: ""
              }))
            : _createCommentVNode("", true)
        ]),
        append: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.barActions, (action) => {
            return (_openBlock(), _createElementBlock("div", {
              key: action.id
            }, [
              (action.type === 'button')
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    icon: action.icon,
                    disabled: _ctx.resolve(action.disabled),
                    onClick: ($event: any) => (action.onClick()),
                    rounded: ""
                  }, null, 8, ["icon", "disabled", "onClick"]))
                : _createCommentVNode("", true),
              (action.type === 'toggle')
                ? (_openBlock(), _createBlock(_component_v_btn_toggle, {
                    key: 1,
                    "model-value": _ctx.getToggle(action.id, action.currentValue),
                    "onUpdate:modelValue": ($event: any) => (_ctx.setToggle(action.id, !!$event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        value: true,
                        icon: _ctx.getToggle(action.id, action.currentValue) && action.iconOn ? action.iconOn : action.icon,
                        disabled: _ctx.resolve(action.disabled),
                        onClick: ($event: any) => (action.onClick(_ctx.getToggle(action.id, action.currentValue))),
                        rounded: ""
                      }, null, 8, ["icon", "disabled", "onClick"])
                    ]),
                    _: 2
                  }, 1032, ["model-value", "onUpdate:modelValue"]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          (_ctx.menuActions?.length)
            ? (_openBlock(), _createBlock(_component_v_menu, { key: 0 }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({ icon: "mdi-dots-vertical" }, props, { rounded: "" }), null, 16)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, { density: "compact" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuActions, (action) => {
                        return (_openBlock(), _createBlock(_component_v_list_item, {
                          key: action.id,
                          onClick: ($event: any) => (action.type === 'toggle' ? action.onClick(_ctx.toggle(action.id, action.currentValue)) : action.onClick())
                        }, {
                          prepend: _withCtx(() => [
                            (action.type === 'button' && action.icon)
                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                  key: 0,
                                  icon: action.icon,
                                  class: "list-item-icon"
                                }, null, 8, ["icon"]))
                              : _createCommentVNode("", true),
                            (action.type === 'toggle')
                              ? (_openBlock(), _createBlock(_component_v_list_item_action, {
                                  key: 1,
                                  start: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_checkbox_btn, {
                                      "model-value": _ctx.getToggle(action.id, action.currentValue)
                                    }, null, 8, ["model-value"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(action.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_title, null, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_Transition, {
                  name: "title",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock("div", { key: _ctx.title }, _toDisplayString(_ctx.title || 'BrainF'), 1))
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_container, {
        class: _normalizeClass(_ctx.noPageScroll ? ['fill-height', 'd-flex', 'flex-column', 'align-stretch'] : [])
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }))
}