import { db } from "@/utils/indexed-db/db";
import { debounce } from "@/utils/utils";
import { BfFile, Settings, initFile } from "@/utils/file";
import { ReadonlyRange } from "@/plugins/codemirror/readonly-ranges/readonly-ranges";
import { BrainfuckWorkerController, DataPacket, IntegerArray } from "@/utils/brainfuck-compiler/brainfuck-worker-controller";
import { BrainfuckWorker } from "@/utils/brainfuck-compiler/worker-preset";
import { StringConverter } from "@/utils/converter/string-converter";
import { Timer } from "@/utils/timer";

export type State = 'reset' | 'running' | 'paused' | 'stopped' | 'error';

export default class EditorViewModel {

    private static readonly DATA_SEND_RATE = 10;

    public fileId?: number;
    public file?: BfFile;
    public tape?: IntegerArray;
    public tapePointer: number | undefined = undefined;
    public currentInstruction: number | undefined = undefined;
    public hasUncommittedChanges: boolean = false;
    public inputPointer: number | undefined = undefined;
    public output: string = "";
    public executedInstructions: number | undefined = undefined;
    public errorMsg: string = "";
    public recentlySaved: boolean = false;
    public errorWhileSaving?: any;
    public debugging: boolean = false;
    public outputCodeUnitLength: number = 0;
    public stringConverter?: StringConverter;
    public executionTime?: number = undefined;
    
    public getCurrentInstructionWithUncommittedChanges: ((currentInstruction: number | undefined, near: boolean) => number | undefined) | undefined;
    public commitChanges: (() => void) | undefined;
    
    private _controller?: BrainfuckWorkerController;
    private _isReset: boolean = false;
    private _state: State = 'reset';
    private _multiStepLongPressTimer?: number = undefined;
    private _multiStepInterStepTimer?: number = undefined;
    private _allowSingleStep: boolean = false;
    private _dataSendTimer?: number = undefined;
    private _runTimer: Timer = new Timer();

    public get title(): string {
        return this.recentlySaved ? 'Saved' : this.file?.title ?? ' ';
    }
    
    public get src(): string {
        return this.initFile().content.sourceCode;
    }

    public set src(value: string) {
        const initFile = this.initFile();
        if (value === initFile.content.sourceCode) {
            return;
        }
        initFile.content.sourceCode = value;
        this.saveFile();
    }

    public get input(): string {
        return this.initFile().content.inputValue;
    }

    public set input(value: string) {
        const initFile = this.initFile();
        if (value === initFile.content.inputValue) {
            return;
        }
        initFile.content.inputValue = value;
        this.saveFile();
        if (this.duringRunState) {
            const inputArray = this.stringConverter!.encode(this.input);
            /* await */ this._controller?.updateInput(inputArray);
        }
    }

    public get inputCodeUnitLength() {
        return this.stringConverter!.computeCodeUnitCount(this.input);
    }

    public get settings(): Settings {
        return this.initFile().content.settings;
    }

    public get showExtraInfo(): boolean {
        return this.settings.display.showExtraInfo;
    }

    public set showExtraInfo(value: boolean) {
        if (value === this.settings.display.showExtraInfo) {
            return;
        }
        this.settings.display.showExtraInfo = value;
        this.saveFile();
    }

    public get state(): State {
        return this._state;
    }

    public set state(value: State) {
        this._state = value;
        this.registerRegularUpdates(value === 'running');
    }

    public get duringRunState(): boolean {
        const s = this.state;
        return s === 'running' || s === 'paused';
    }

    public get runTime(): number | undefined {
        return this._runTimer.time;
    }

    public get executionSpeed(): number | undefined {
        return this.executedInstructions == null || this.executionTime == null ? undefined : this.executedInstructions / this.executionTime;
    }

    public get inputReadonlyRanges(): ReadonlyRange[] {
        if (!this.inputPointer) {
            return [];
        }
        const to = this.stringConverter!.computeCharacterCount(this.input, this.inputPointer);
        return [{ from: 0, to: to }];
    }

    public async initialize(fileId: string | string[]): Promise<void> {
        if (Array.isArray(fileId) || Number.isNaN(+fileId)) {
            throw new Error("Id is not a number");
        }
        this.fileId = +fileId;
        const filesStore = await db.files();
        this.file = await filesStore.get(this.fileId);
        this.stringConverter = new StringConverter(this.settings.execution, BrainfuckWorker.MAX_OUTPUT_SIZE);
        this._controller = new BrainfuckWorkerController(this.settings.execution);
        this._controller.addDataReceivedListener(data => this.updateData(data));
        this._controller.addFinishedListener(() => this.endRun());
        this._controller.addWaitingListener(() => this.pauseRun());
        this._controller.addErrorListener(msg => this.handleError(msg));
    }

    public async uninitialize(): Promise<void> {
        this.registerRegularUpdates(false);
        this._controller?.terminate();
    }

    public reset() {
        this.tape = undefined;
        this.tapePointer = undefined;
        this.currentInstruction = undefined;
        this.inputPointer = undefined;
        this.output = "";
        this.executedInstructions = undefined;
        this._isReset = true;
        this.state = 'reset';
        this.debugging = false;
        this.errorMsg = "";
        this.outputCodeUnitLength = 0;
        this.stringConverter?.resetDecode();
        this._runTimer.reset();
        this.executionTime = undefined;
    }

    public async stop() {
        await this._controller?.stop();
    }

    public async pause() {
        await this._controller?.pause();
    }

    public async run() {
        await this.newRun(false);
    }

    public async debug() {
        await this.newRun(true);
    }

    public async continue() {
        this.state = 'running';
        this._runTimer.continue();
        await this._controller?.continue();
    }

    public async continueWithoutBreakpoints() {
        this.state = 'running';
        this._runTimer.continue();
        await this._controller?.continueWithoutBreakpoints();
    }

    public async step() {
        this._runTimer.continue();
        await this._controller?.step();
    }

    public multiStepStart() {
        clearTimeout(this._multiStepLongPressTimer);
        this._multiStepLongPressTimer = undefined;
        clearInterval(this._multiStepInterStepTimer);
        this._multiStepInterStepTimer = undefined;
        this._allowSingleStep = true;

        this._multiStepLongPressTimer = window.setTimeout(() => {
            clearTimeout(this._multiStepLongPressTimer);
            this._allowSingleStep = false;
            this._multiStepLongPressTimer = undefined;
            this._multiStepInterStepTimer = window.setInterval(() => {
                if (this.state !== 'paused') {
                    clearTimeout(this._multiStepLongPressTimer);
                    this._multiStepLongPressTimer = undefined;
                    clearInterval(this._multiStepInterStepTimer);
                    this._multiStepInterStepTimer = undefined;
                    return;
                }
                this.step();
            }, 20);
        }, 1000);
    }

    public multiStepStop(allowAction: boolean) {
        if (allowAction && this._allowSingleStep) {
            this._allowSingleStep = false;
            this.step();
        }
        clearTimeout(this._multiStepLongPressTimer);
        this._multiStepLongPressTimer = undefined;
        clearInterval(this._multiStepInterStepTimer);
        this._multiStepInterStepTimer = undefined;
    }

    public async updateSource() {
        const mappedInstruction = this.getCurrentInstructionWithUncommittedChanges?.(this.currentInstruction, true);
        if (mappedInstruction == null) {
            throw new Error("Cannot convert position between before and after changes");
        }
        await this._controller?.updateSource(this.src, mappedInstruction, () => this.commitChanges?.());
    }

    public async changeCellValue(tapeCellIndex: number, tapeCellValue: number, changeActiveCell: boolean) {
        this.tape![tapeCellIndex] = tapeCellValue;
        if (changeActiveCell) {
            this.tapePointer = tapeCellIndex;
            this._controller?.updateTapeCellAndPointer(tapeCellIndex, tapeCellValue, tapeCellIndex);
        } else {
            this._controller?.updateTapeCell(tapeCellIndex, tapeCellValue);
        }
    }

    private async newRun(debuggable: boolean) {
        if (!this._isReset) {
            this.reset();
        }
        this._isReset = false;
        this._runTimer.start();
        this.executionTime = 0;
        this.commitChanges?.();
        
        const input = this.stringConverter!.encode(this.input);
        await this._controller?.run(this.src, input, debuggable);

        this.state = 'running';
        this.debugging = debuggable;
    }

    private registerRegularUpdates(run: boolean) {
        if (!run) {
            clearInterval(this._dataSendTimer);
            this._dataSendTimer = undefined;
            return;
        }
        if (run && this._dataSendTimer) {
            return;
        }
        this._dataSendTimer = window.setInterval(async () => {
            await this._controller?.queryData();
        }, 1000 / EditorViewModel.DATA_SEND_RATE);
    }

    private async updateData(data: DataPacket) {
        this.output = this.stringConverter!.decodeContinued(data.output);
        this.outputCodeUnitLength = data.outputLength;
        this.tape = data.tape;
        this.tapePointer = data.tapePointer;
        this.currentInstruction = data.currentInstruction;
        this.inputPointer = data.inputPointer;
        this.executedInstructions = data.executedInstructions;
        if (this.executionTime != null && data.fromTime != null && data.toTime != null) {
            this.executionTime += data.toTime - data.fromTime;
        }
    }

    private endRun() {
        this._runTimer.stop();
        this.state = 'stopped';
    }

    private pauseRun() {
        this._runTimer.stop();
        this.state = 'paused';
    }

    private handleError(msg: string) {
        this._runTimer.stop();
        this.state = 'error';
        this.errorMsg = msg;
    }

    private initFile() {
        return initFile(this.file);
    }

    private saveFile() {
        debounce(async () => {
            try {
                if (this.file) {
                    const filesStore = await db.files();
                    await filesStore.put(this.file);
                    this.recentlySaved = true;
                    this.errorWhileSaving = undefined;
                    setTimeout(() => {
                        this.recentlySaved = false;
                    }, 1000);
                } else {
                    this.errorWhileSaving = "No file to save";
                }
            } catch (ex: any) {
                this.errorWhileSaving = ex;
            }
        }, 1000, this)();
    }
}
