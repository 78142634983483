import { getReadonlyRanges, ReadonlyRange, readonlyRanges, setReadonlyRanges } from "@/plugins/codemirror/readonly-ranges/readonly-ranges";
import { isReadonly, readonly, setReadonly } from "@/plugins/codemirror/readonly/readonly";
import CodeMirrorEditor from "@/plugins/codemirror/codemirror-editor";

export default class BfTextAreaViewModel {

    private _cmEditor?: CodeMirrorEditor;
    private _source: string = '';

    public get source(): string {
        return this._source;
    }

    public set source(value: string) {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        this._cmEditor.source = value;
    }

    public get readonly(): boolean {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        return isReadonly(this._cmEditor.view);
    }

    public set readonly(value: boolean) {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        setReadonly(this._cmEditor.view, value);
    }

    public get readonlyRanges(): ReadonlyRange[] {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        return getReadonlyRanges(this._cmEditor.view);
    }

    public set readonlyRanges(ranges: ReadonlyRange[]) {
        if (!this._cmEditor) {
            throw new Error('Editor not yet created');
        }
        setReadonlyRanges(this._cmEditor.view, ranges);
    }

    public async initialize(id: string, height: string): Promise<void> {
        this._cmEditor = new CodeMirrorEditor(id, height, [
            readonly(),
            readonlyRanges(),
        ]);
        this._cmEditor.addChangeListener(source => this._source = source);
    }
}
