import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_bf_code_editor = _resolveComponent("bf-code-editor")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_bf_grow_only = _resolveComponent("bf-grow-only")!
  const _component_bf_text_info = _resolveComponent("bf-text-info")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_bf_text_area = _resolveComponent("bf-text-area")!
  const _component_bf_memory_viewer = _resolveComponent("bf-memory-viewer")!
  const _component_layout_default = _resolveComponent("layout-default")!

  return (_openBlock(), _createBlock(_component_layout_default, {
    title: _ctx.vm.title,
    actions: _ctx.actions,
    "has-back-button": true,
    onBack: _cache[21] || (_cache[21] = ($event: any) => (_ctx.$router.back()))
  }, {
    default: _withCtx(() => [
      (_ctx.vm.errorWhileSaving)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_alert, {
                text: _ctx.vm.errorWhileSaving.toString(),
                type: "error",
                variant: "tonal"
              }, null, 8, ["text"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_bf_code_editor, {
            ref: "codeEditor",
            modelValue: _ctx.vm.src,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vm.src) = $event)),
            "current-instruction": _ctx.vm.currentInstruction,
            "onUpdate:currentInstruction": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vm.currentInstruction) = $event)),
            "show-changes": _ctx.vm.duringRunState,
            "show-text-info": _ctx.vm.showExtraInfo,
            onHasUncommittedChanges: _cache[2] || (_cache[2] = ($event: any) => (_ctx.vm.hasUncommittedChanges = $event))
          }, null, 8, ["modelValue", "current-instruction", "show-changes", "show-text-info"])
        ]),
        _: 1
      }),
      (_ctx.vm.state === 'error')
        ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_alert, {
                text: _ctx.vm.errorMsg,
                type: "error",
                variant: "tonal"
              }, null, 8, ["text"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_sheet, {
            class: "w-100 pa-4 my-2",
            color: "grey-lighten-3",
            rounded: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "d-flex flex-wrap justify-center ga-4" }, {
                    default: _withCtx(() => [
                      (!_ctx.vm.duringRunState)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            icon: "mdi-restore",
                            disabled: _ctx.vm.state === 'reset',
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.vm.reset())),
                            rounded: ""
                          }, null, 8, ["disabled"]))
                        : _createCommentVNode("", true),
                      (!_ctx.vm.duringRunState)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 1,
                            icon: "mdi-play",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.vm.run())),
                            rounded: ""
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.vm.duringRunState)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 2,
                            icon: "mdi-bug-play-outline",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.vm.debug())),
                            rounded: ""
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.vm.duringRunState)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 3,
                            icon: "mdi-stop",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.vm.stop())),
                            rounded: ""
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.vm.duringRunState)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 4,
                            icon: "mdi-pause",
                            disabled: _ctx.vm.state !== 'running',
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.vm.pause())),
                            rounded: ""
                          }, null, 8, ["disabled"]))
                        : _createCommentVNode("", true),
                      (_ctx.vm.duringRunState)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 5,
                            icon: "mdi-step-forward",
                            disabled: _ctx.vm.state !== 'paused',
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.vm.continue())),
                            rounded: ""
                          }, null, 8, ["disabled"]))
                        : _createCommentVNode("", true),
                      (_ctx.vm.duringRunState && _ctx.vm.debugging)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 6,
                            icon: "mdi-skip-forward",
                            disabled: _ctx.vm.state !== 'paused',
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.vm.continueWithoutBreakpoints())),
                            rounded: ""
                          }, null, 8, ["disabled"]))
                        : _createCommentVNode("", true),
                      (_ctx.vm.duringRunState && _ctx.vm.debugging)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 7,
                            icon: "mdi-debug-step-over",
                            disabled: _ctx.vm.state !== 'paused',
                            onMousedown: _cache[10] || (_cache[10] = ($event: any) => (_ctx.vm.multiStepStart())),
                            onTouchstart: _cache[11] || (_cache[11] = ($event: any) => (_ctx.vm.multiStepStart())),
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.vm.multiStepStop(true))),
                            onMouseup: _cache[13] || (_cache[13] = ($event: any) => (_ctx.vm.multiStepStop(false))),
                            onMouseout: _cache[14] || (_cache[14] = ($event: any) => (_ctx.vm.multiStepStop(false))),
                            onTouchend: _cache[15] || (_cache[15] = ($event: any) => (_ctx.vm.multiStepStop(false))),
                            onTouchcancel: _cache[16] || (_cache[16] = ($event: any) => (_ctx.vm.multiStepStop(false))),
                            rounded: ""
                          }, null, 8, ["disabled"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_btn, {
                        icon: "mdi-receipt-text-arrow-left-outline",
                        disabled: _ctx.vm.state === 'reset',
                        onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.codeEditor().scrollToCodePointer())),
                        rounded: ""
                      }, null, 8, ["disabled"]),
                      (_ctx.vm.duringRunState && _ctx.vm.debugging)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 8,
                            icon: "mdi-receipt-text-check-outline",
                            disabled: !_ctx.vm.hasUncommittedChanges,
                            onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.vm.updateSource())),
                            rounded: ""
                          }, null, 8, ["disabled"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.executionTextInfo?.length)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 0,
                    dense: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_bf_text_info, { "model-value": _ctx.executionTextInfo }, _createSlots({ _: 2 }, [
                            (_ctx.nextInstructionAt)
                              ? {
                                  name: "value-next-instruction-at",
                                  fn: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.nextInstructionAt.type === 'near' ? 'around ' : '') + "Line ", 1),
                                    _createVNode(_component_bf_grow_only, { class: "text-right mr-1" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.nextInstructionAt.line), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createTextVNode(" Character "),
                                    _createVNode(_component_bf_grow_only, { class: "text-right" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.nextInstructionAt.character), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  key: "0"
                                }
                              : undefined
                          ]), 1032, ["model-value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_bf_text_area, {
            modelValue: _ctx.vm.input,
            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.vm.input) = $event)),
            label: "Input",
            height: "6rem",
            "text-info": _ctx.inputTextInfo,
            "readonly-ranges": _ctx.vm.inputReadonlyRanges
          }, null, 8, ["modelValue", "text-info", "readonly-ranges"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_bf_memory_viewer, {
            tape: _ctx.vm.tape,
            "tape-size": _ctx.vm.settings.execution.tapeSize,
            "cell-size": _ctx.vm.settings.execution.cellSize,
            "signed-cell": _ctx.vm.settings.execution.signedCell,
            "active-cell": _ctx.vm.tapePointer,
            readonly: !_ctx.vm.duringRunState || !_ctx.vm.debugging,
            "show-text-info": _ctx.vm.showExtraInfo,
            "string-converter": _ctx.vm.stringConverter,
            onSaved: _cache[20] || (_cache[20] = ($event: any) => (_ctx.vm.changeCellValue($event.cellIndex, $event.newCellValue, $event.isNewActiveCell)))
          }, null, 8, ["tape", "tape-size", "cell-size", "signed-cell", "active-cell", "readonly", "show-text-info", "string-converter"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_bf_text_area, {
            "model-value": _ctx.vm.output,
            label: "Output",
            height: "20rem",
            "text-info": _ctx.outputTextInfo,
            readonly: ""
          }, null, 8, ["model-value", "text-info"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "actions"]))
}