import { IntegerArray } from "../brainfuck-compiler/brainfuck-worker-controller";
import { ExecSettings } from "../file";
import * as windows1252 from "windows-1252";

const enum Utf8 {
    BitMaskIsComposite = 0x80,
    BitMaskIFCompositeHasAtLeast2Byte = 0x40,
    BitMaskIFCompositeHasAtLeast3Byte = 0x20,
    BitMaskIFCompositeHasAtLeast4Byte = 0x10,
    BitMaskIFCompositeHasAtLeast5Byte = 0x08,
    BitMaskFollowingSurrogates = 0xC0,
    BitValueFollowingSurrogates = 0x80,
    Max1ByteCodePoint = 0x7F,
    Max2ByteCodePoint = 0x7FF,
    Max3ByteCodePoint = 0xFFFF,
}

const enum Utf16 {
    BitMaskCompositeType = 0xFC00,
    BitValueCompositeHighSurrogate = 0xD800,
    BitValueCompositeLowSurrogate = 0xDC00,
    MaxSingleValueCodePoint = 0xFFFF,
    BitValueReplacementChar = 0xFFFD,
}

const replacementChar = '\uFFFD';

export class StringConverter {

    private _settings: ExecSettings;
    private _encoder: TextEncoder;
    private _decoder: TextDecoder;
    private _maxOutputSize: number;

    private _decodeCommited: string = "";
    private _decodeUncommitted: number[] = [];

    public constructor(settings: ExecSettings, maxOutputSize: number) {
        this._settings = settings;
        this._maxOutputSize = maxOutputSize;
        this._encoder = new TextEncoder();
        this._decoder = new TextDecoder(this._settings.characterSet, { ignoreBOM: true });
    }

    private get decodedString() {
        return this._decodeCommited + (this._decodeUncommitted.length === 0 ? "" : replacementChar);
    }
    
    public resetDecode() {
        this._decodeCommited = "";
        this._decodeUncommitted = [];
    }

    public encode(value: string): IntegerArray {
        let normalized;
        switch (this._settings.newLine) {
            case 'n':
                normalized = value;
                break;
            case 'rn':
                normalized = value.replaceAll('\n', '\r\n');
                break;
            case 'r':
                normalized = value.replaceAll('\n', '\r');
                break;
        }
        return this.stringToBytes(normalized, false);
    }

    public decodeContinued(value: number[]): string {
        if (value.length === 0) {
            return this.decodedString;
        }
        const extended = this._decodeUncommitted.concat(value);
        let commited: number[];
        let uncommitted: number[];
        switch (this._settings.characterSet) {
            case 'ascii': {
                commited = extended;
                uncommitted = [];
                break;
            }
            case 'utf-8': {
                let i: number = 0;
                let back: number = 0;
                let v: number;
                while (extended.length > i && ((v = extended[extended.length - i - 1]) & Utf8.BitMaskIsComposite) !== 0) {
                    i++;
                    if ((v & Utf8.BitMaskIFCompositeHasAtLeast2Byte) === 0) {
                        continue;
                    }
                    if ((v & Utf8.BitMaskIFCompositeHasAtLeast3Byte) === 0) {
                        back = i >= 2 ? 0 : i;
                        break;
                    }
                    if ((v & Utf8.BitMaskIFCompositeHasAtLeast4Byte) === 0) {
                        back = i >= 3 ? 0 : i;
                        break;
                    }
                    if ((v & Utf8.BitMaskIFCompositeHasAtLeast5Byte) === 0) {
                        back = i >= 4 ? 0 : i;
                        break;
                    }
                }
                if (back === 0) {
                    commited = extended;
                    uncommitted = [];
                } else {
                    commited = extended.slice(0, -back);
                    uncommitted = extended.slice(-back);
                }
                break;
            }
            case 'utf-16': {
                if ((extended[extended.length - 1] & Utf16.BitMaskCompositeType) === Utf16.BitValueCompositeHighSurrogate) {
                    commited = extended.slice(0, -1);
                    uncommitted = extended.slice(-1);
                } else {
                    commited = extended;
                    uncommitted = [];
                }
                break;
            }
        }
        if (commited.length) {
            const decoded = this.bytesToString(commited);
            let normalized: string;
            switch (this._settings.newLine) {
                case 'n':
                    normalized = decoded;
                    break;
                case 'rn':
                    normalized = decoded.replaceAll('\r\n', '\n');
                    break;
                case 'r':
                    normalized = decoded.replaceAll('\r', '\n');
                    break;
            }
            this._decodeCommited += normalized;
        }
        const len = this._decodeCommited.length;
        if (len >= this._maxOutputSize) {
            this._decodeCommited = this._decodeCommited.substring(len - this._maxOutputSize, len);
        }
        this._decodeUncommitted = uncommitted;

        return this.decodedString;
    }

    public fromChar(v: string): IntegerArray | undefined {
        return this.stringToBytes(v, true);
    }

    public toChar(v: number): string {
        const uft8Extended = this._settings.characterSet === 'utf-8' && (v & Utf8.BitMaskFollowingSurrogates) === Utf8.BitValueFollowingSurrogates;
        const uft16Extended = this._settings.characterSet === 'utf-16' && (v & Utf16.BitMaskCompositeType) === Utf16.BitValueCompositeLowSurrogate;
        const values = this._decodeUncommitted && (uft8Extended || uft16Extended) ? this._decodeUncommitted.concat(v) : [v];
        return this.bytesToString(values);
    }

    public computeCharacterCount(value: string, codeUnitCount: number) {
        if (codeUnitCount === 0) {
            return 0;
        }

        const rn = this._settings.newLine === 'rn';

        switch (this._settings.characterSet) {
            case 'ascii': {
                let count = 0;
                for (let i = 0; i < value.length;) {
                    count += rn && value.charAt(i) === '\n' ? 2 : 1;
                    const code = value.codePointAt(i);
                    if (code != null && code > Utf16.MaxSingleValueCodePoint) {
                        i += 2;
                    } else {
                        i += 1;
                    }
                    if (count >= codeUnitCount) {
                        return i;
                    }
                }
                return value.length + 1;
            }
            case 'utf-8': {
                let count = 0;
                for (let i = 0; i < value.length;) {
                    if (rn && value.charAt(i) === '\n') {
                        count += 1;
                    }
                    const code = value.codePointAt(i);
                    if (code == null) {
                        i += 1;
                    } else if (code <= Utf8.Max1ByteCodePoint) {
                        count += 1,
                        i += 1;
                    } else if (code <= Utf8.Max2ByteCodePoint) {
                        count += 2;
                        i += 1;
                    } else if (code <= Utf8.Max3ByteCodePoint) {
                        count += 3;
                        i += 1;
                    } else {
                        count += 4;
                        i += 2;
                    }
                    if (count >= codeUnitCount) {
                        return i;
                    }
                }
                return value.length + 1;
            }
            case 'utf-16': {
                let result = codeUnitCount;
                if (rn) {
                    let count = 0;
                    for (let i = 0; i < value.length;) {
                        count += value.charAt(i) === '\n' ? 2 : 1;
                        i++;
                        if (count >= codeUnitCount) {
                            result = i;
                            break;
                        }
                    }
                }
                if (result > value.length) {
                    return value.length + 1;
                } else {
                    const code = value.codePointAt(result - 1);
                    return (code != null && code > Utf16.MaxSingleValueCodePoint) ? result + 1 : result;
                }
            }
        }
    }

    public computeCodeUnitCount(value: string) {
        const rn = this._settings.newLine === 'rn';

        switch (this._settings.characterSet) {
            case 'ascii': {
                let count = 0;
                for (let i = 0; i < value.length;) {
                    count += rn && value.charAt(i) === '\n' ? 2 : 1;
                    const code = value.codePointAt(i);
                    if (code != null && code > Utf16.MaxSingleValueCodePoint) {
                        i += 2;
                    } else {
                        i += 1;
                    }
                }
                return count;
            }
            case 'utf-8': {
                let count = 0;
                for (let i = 0; i < value.length;) {
                    if (rn && value.charAt(i) === '\n') {
                        count += 1;
                    }
                    const code = value.codePointAt(i);
                    if (code == null) {
                        i += 1;
                    } else if (code <= Utf8.Max1ByteCodePoint) {
                        count += 1,
                        i += 1;
                    } else if (code <= Utf8.Max2ByteCodePoint) {
                        count += 2;
                        i += 1;
                    } else if (code <= Utf8.Max3ByteCodePoint) {
                        count += 3;
                        i += 1;
                    } else {
                        count += 4;
                        i += 2;
                    }
                }
                return count;
            }
            case 'utf-16': {
                let count = value.length;
                if (rn) {
                    for (let i = 0; i < value.length; i++) {
                        if (value.charAt(i) === '\n') {
                            count++;
                        }
                    }
                }
                return count;
            }
        }
    }

    public static removeCompositeChars(value: string): string {
        let result = "";
        let uncopiedIndex = 0;
        for (let i = 0; i < value.length - 1; i++) {
            const code = value.codePointAt(i);
            if (code != null && code > Utf16.MaxSingleValueCodePoint) {
                result += value.substring(uncopiedIndex, i);
                result += replacementChar;
                uncopiedIndex = i + 2;
                i++;
            }
        }
        result += value.substring(uncopiedIndex);
        return result;
    }

    private stringToBytes(value: string, undefinedIfNotPossible: false): IntegerArray;
    private stringToBytes(value: string, undefinedIfNotPossible: boolean): IntegerArray | undefined;
    private stringToBytes(value: string, undefinedIfNotPossible: boolean): IntegerArray | undefined {
        let result;
        let encodingMaxValue;
        switch (this._settings.characterSet) {
            case 'ascii': {
                const withoutCompositeChars = StringConverter.removeCompositeChars(value);
                const encoded = windows1252.encode(withoutCompositeChars, { mode: 'replacement' });
                if (undefinedIfNotPossible && encoded.some(v => v === Utf16.BitValueReplacementChar)) {
                    return undefined;
                }
                result = new Uint8Array(encoded.map(v => v === Utf16.BitValueReplacementChar ? 127 : v));
                encodingMaxValue = 2**8 - 1;
                break;
            }
            case 'utf-8': {
                result = this._encoder.encode(value);
                encodingMaxValue = 2**8 - 1;
                break;
            }
            case 'utf-16': {
                result = new Uint16Array(value.length);
                for (let i = 0, len = value.length; i < len; i++) {
                    result[i] = value.charCodeAt(i);
                }
                encodingMaxValue = 2**16 - 1;
                break;
            }
        }
        const cellSize = this._settings.cellSize;
        const cellMaxValue = this._settings.signedCell ? Math.ceil(cellSize / 2) - 1 : cellSize - 1;
        if (encodingMaxValue > cellMaxValue) {
            if (undefinedIfNotPossible && result.some(v => v >= cellSize)) {
                return undefined;
            }
            result = result.map(v => {
                let newV = (v % cellSize);
                if (newV > cellMaxValue) {
                    newV -= cellSize;
                }
                return newV;
            });
        }
        return result;
    }

    private bytesToString(value: number[] | IntegerArray): string {
        const int8 = this._settings.characterSet !== 'utf-16';
        const arr = int8 ? new Uint8Array(value) : new Uint16Array(value);
        return this._decoder.decode(arr);
    }
}
