<template>
  <v-app>
    <v-app-bar density="compact" class="position-sticky">
      <template v-slot:prepend>
        <v-btn v-if="hasBackButton" icon="mdi-arrow-left" @click="$emit('back')" rounded></v-btn>
      </template>
      <v-app-bar-title>
        <div>
          <transition name="title" mode="out-in">
            <div :key="title">
              {{ title || 'BrainF' }}
            </div>
          </transition>
        </div>
      </v-app-bar-title>
      <template v-slot:append>
        <div v-for="action in barActions" :key="action.id">
          <v-btn
            v-if="action.type === 'button'"
            :icon="action.icon"
            :disabled="resolve(action.disabled)"
            @click="action.onClick()"
            rounded>
          </v-btn>
          <v-btn-toggle
            v-if="action.type === 'toggle'"
            :model-value="getToggle(action.id, action.currentValue)"
            @update:model-value="setToggle(action.id, !!$event)">
            <v-btn
              :value="true"
              :icon="getToggle(action.id, action.currentValue) && action.iconOn ? action.iconOn : action.icon"
              :disabled="resolve(action.disabled)"
              @click="action.onClick(getToggle(action.id, action.currentValue))"
              rounded>
            </v-btn>
          </v-btn-toggle>
        </div>
        <v-menu v-if="menuActions?.length">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-dots-vertical" v-bind="props" rounded></v-btn>
          </template>
          <v-list density="compact">
            <v-list-item v-for="action in menuActions" :key="action.id" @click="action.type === 'toggle' ? action.onClick(toggle(action.id, action.currentValue)) : action.onClick()">
              <template v-slot:prepend>
                <v-icon v-if="action.type === 'button' && action.icon" :icon="action.icon" class="list-item-icon"></v-icon>
                <v-list-item-action v-if="action.type === 'toggle'" start>
                  <v-checkbox-btn :model-value="getToggle(action.id, action.currentValue)"></v-checkbox-btn>
                </v-list-item-action>
              </template>
              <v-list-item-title>{{ action.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
    <v-container :class="noPageScroll ? ['fill-height', 'd-flex', 'flex-column', 'align-stretch'] : []">
      <slot></slot>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export type Action = {
  id: string,
  icon?: string,
  disabled?: boolean | (() => boolean),
} & ({
  type: 'button',
  onClick: () => void,
} | {
  type: 'toggle',
  currentValue: boolean,
  iconOn?: string,
  onClick: (on: boolean) => void, // eslint-disable-line no-unused-vars
}) & ({
  location: 'bar',
} | {
  location: 'menu',
  title: string,
});

export default defineComponent({
  name: 'layout-default',
  props: {
    actions: { type: Array as PropType<Action[]>, default: () => [] },
    title: { type: String, default: '' },
    hasBackButton: { type: Boolean, default: false },
    noPageScroll: { type: Boolean, default: false },
  },
  data() {
    return {
      toggles: new Map<string, boolean>(),
    }
  },
  emits: ['back'],
  computed: {
    barActions() {
      return this.actions.filter(a => a.location === 'bar');
    },
    menuActions() {
      return this.actions.filter(a => a.location === 'menu');
    },
  },
  methods: {
    resolve<T>(value: T | (() => T)): T {
      return typeof value === 'function' ? (value as any)() : value;
    },
    getToggle(id: string, defaultValue: boolean): boolean {
      return this.toggles.get(id) ?? defaultValue;
    },
    setToggle(id: string, value: boolean) {
      this.toggles.set(id, value);
    },
    toggle(id: string, defaultValue: boolean): boolean {
      const newValue = !this.getToggle(id, defaultValue);
      this.toggles.set(id, newValue);
      return newValue;
    }
  },
})
</script>

<style scoped>
  .title-enter-active,
  .title-leave-active {
    transition: all .15s ease;
  }

  .title-enter-from {
    transform: translateY(-3px);
    opacity: 0;
  }

  .title-leave-to {
    transform: translateY(3px);
    opacity: 0;
  }

  .list-item-icon {
    margin-left: 8px;
    margin-right: -12px;
  }
</style>
