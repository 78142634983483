export default class AsyncMutex {

    private _locked: boolean = false;
    private _notity: ((value: void) => void)[] = [];

    public constructor() {
    }

    public isLockFree(): boolean {
        return !this._locked;
    }

    public async lock() {
        while (this._locked) {
            await new Promise(resolve => this._notity.push(resolve));
        }
        this._locked = true;
    }

    public tryLock(): boolean {
        if (this._locked) {
            return false;
        }
        this._locked = true;
        return true;
    }

    public unlock() {
        if (!this._locked) {
            throw new Error("Async mutex is in an inconsistent state: Cannot unlock on unlocked mutex.");
        }
        this._locked = false;
        this._notity.shift()?.();
    }

    public async whileLocked<T>(callback: () => Promise<T>): Promise<T> {
        await this.lock();
        try {
            return await callback();
        } finally {
            this.unlock();
        }
    }
}
