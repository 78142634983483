import Sync, { Action } from "../synchronization/sync";
import BrainfuckCompiler from "./brainfuck-compiler";
import { ExecSettings } from "../file";
import AsyncMutex from "../synchronization/async-mutex";

export type IntegerArray = Uint8Array | Uint16Array | Uint32Array;
export type DataPacket = {
    output: number[],
    outputLength: number,
    tape: IntegerArray,
    tapePointer: number,
    inputPointer: number,
    currentInstruction: number | undefined,
    executedInstructions: number,
    fromTime: number | undefined,
    toTime: number | undefined,
};
export type ToWorkerMessage = { type: 'sync', data: { syncBuffer: Int32Array, initEmptyTape: boolean, initTape: IntegerArray | undefined } };
export type FromWorkerMessage = { type: 'data', data: DataPacket } 
    | { type: 'end' | 'pause' }
    | { type: 'error', data: { msg: string } }
    | { type: 'request', data: { target: 'input', buffer: IntegerArray } }
    | { type: 'updateSource', data: { wait: boolean } };

export class BrainfuckWorkerController {
    
    private _settings: ExecSettings;
    
    private _controllerMutex: AsyncMutex;
    private _worker?: Worker;
    private _workerSync?: Sync;
    
    private _lastUpdatedInput?: IntegerArray;
    private _lastReceivedData?: DataPacket;

    private _handleInputRequestResolve?: (buffer: IntegerArray) => void;
    private _handleInputRequestReject?: (reason?: any) => void;
    private _handleUpdateSourceResolve?: (wait: boolean) => void;
    private _handleUpdateSourceReject?: (reason?: any) => void;

    private _dataReceivedListeners: ((data: DataPacket) => void)[] = [];
    private _finishedListeners: (() => void)[] = [];
    private _waitingListeners: (() => void)[] = [];
    private _errorListeners: ((msg: string) => void)[] = [];

    public constructor(settings: ExecSettings) {
        this._settings = settings;
        this._controllerMutex = new AsyncMutex();
    }

    public async run(source: string, input: IntegerArray, debuggable: boolean) {
        await this._controllerMutex.whileLocked(async () => await this.execRun(source, input, debuggable));
    }

    public async continue() {
        await this._controllerMutex.whileLocked(async () => await this.sendAction('continue'));
    }

    public async continueWithoutBreakpoints() {
        await this._controllerMutex.whileLocked(async () => await this.sendAction('continue-without-breakpoints'));
    }

    public async step() {
        await this._controllerMutex.whileLocked(async () => await this.sendAction('single-step'));
    }

    public async pause() {
        await this._controllerMutex.whileLocked(async () => await this.sendAction('pause'));
    }

    public async stop() {
        await this._controllerMutex.whileLocked(async () => await this.sendAction('stop'));
    }

    public terminate() {
        this._worker?.terminate();
        this._worker = undefined;
        this._workerSync = undefined;
        this._handleInputRequestReject?.('Worker has been terminated');
        this._handleInputRequestResolve = undefined;
        this._handleInputRequestReject = undefined;
        this._handleUpdateSourceReject?.('Worker has been terminated');
        this._handleUpdateSourceResolve = undefined;
        this._handleUpdateSourceReject = undefined;
    }

    public async updateTapeCell(tapeCellIndex: number, tapeCellValue: number) {
        await this._controllerMutex.whileLocked(async () => await this.sendTapeCell(tapeCellIndex, tapeCellValue));
    }

    public async updateTapePointer(tapePointer: number) {
        await this._controllerMutex.whileLocked(async () => await this.sendTapePointer(tapePointer));
    }

    public async updateTapeCellAndPointer(tapeCellIndex: number, tapeCellValue: number, tapePointer: number) {
        await this._controllerMutex.whileLocked(async () => await this.sendTapeCellAndPointer(tapeCellIndex, tapeCellValue, tapePointer));
    }

    public async updateInput(input: IntegerArray) {
        await this._controllerMutex.whileLocked(async () => await this.sendInput(input));
    }

    public async updateInputPointer(inputPointer: number) {
        await this._controllerMutex.whileLocked(async () => await this.sendInputPointer(inputPointer));
    }

    public async updateSource(source: string, startAt: number, afterTerminateCallback: () => void) {
        await this._controllerMutex.whileLocked(async () => await this.execUpdateSource(source, startAt, afterTerminateCallback));
    }

    public async queryData() {
        await this._controllerMutex.whileLocked(async () => await this.execQueryData());
    }

    public addDataReceivedListener(callback: (data: DataPacket) => void) {
        this.addListener(callback, this._dataReceivedListeners);
    }

    public removeDataReceivedListener(callback: (data: DataPacket) => void) {
        this.removeListener(callback, this._dataReceivedListeners);
    }

    public addFinishedListener(callback: () => void) {
        this.addListener(callback, this._finishedListeners);
    }

    public removeFinishedListener(callback: () => void) {
        this.removeListener(callback, this._finishedListeners);
    }

    public addWaitingListener(callback: () => void) {
        this.addListener(callback, this._waitingListeners);
    }

    public removeWaitingListener(callback: () => void) {
        this.removeListener(callback, this._waitingListeners);
    }

    public addErrorListener(callback: (msg: string) => void) {
        this.addListener(callback, this._errorListeners);
    }

    public removeErrorListener(callback: (msg: string) => void) {
        this.removeListener(callback, this._errorListeners);
    }

    private async execRun(source: string, input: IntegerArray, debuggable: boolean) {
        if (this._worker) {
            throw new Error('Worker is already running');
        }
        this.createWorkerAndSync(source, debuggable, undefined, true);

        const sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync();
        sync.update = true;
        const action = this.sendAction('run');
        const sendInput = this.sendInput(input);
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }
        
        await Promise.all([action, sendInput]);
    }

    private createWorkerAndSync(source: string, debuggable: boolean, startAt: number | undefined, initEmptyTape: boolean, initTape?: IntegerArray) {
        this._worker = this.createWorker(source, debuggable, startAt ?? 0);
        this._workerSync = new Sync();
        this.sendMessageToWorker({ type: 'sync', data: { syncBuffer: this._workerSync.buffer, initEmptyTape, initTape } });
    }

    private createWorker(source: string, debuggable: boolean, startAt: number = 0): Worker {
        const code = new BrainfuckCompiler(this._settings).compile(source, debuggable, startAt);
        const blob = new Blob([code], { type: 'application/javascript' });
        const blobUrl = URL.createObjectURL(blob);
        const worker = new Worker(blobUrl);
        URL.revokeObjectURL(blobUrl);
        worker.addEventListener("message", (event) => this.onMessageFormWorker(event));
        return worker;
    }

    private async sendAction(action: Action) {
        const sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync();
        sync.update = true;
        sync.action = action;
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }
    }

    private async sendTapeCell(tapeCellIndex: number, tapeCellValue: number) {
        const sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync();
        if (sync.updateTapeCell && sync.updateTapeCellIndex !== tapeCellIndex) {
            throw new Error('A tape cell is already being updated');
        }
        sync.update = true;
        sync.updateTapeCell = true;
        sync.updateTapeCellIndex = tapeCellIndex;
        sync.updatedTapeCell = tapeCellValue;
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }
    }

    private async sendTapePointer(tapePointer: number) {
        const sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync();
        sync.update = true;
        sync.updateTapePointer = true;
        sync.updatedTapePointer = tapePointer;
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }
    }

    private async sendTapeCellAndPointer(tapeCellIndex: number, tapeCellValue: number, tapePointer: number) {
        const sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync();
        sync.update = true;
        const sendTapeCell = this.sendTapeCell(tapeCellIndex, tapeCellValue);
        const sendTapePointer = this.sendTapePointer(tapePointer);
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }

        await Promise.all([sendTapeCell, sendTapePointer]);
    }

    private async sendInput(input: IntegerArray) {
        if (this._handleInputRequestResolve) {
            throw new Error('Invalid state - sendInputAndPointer is alreay called');
        }
        this._lastUpdatedInput = input;
        
        const sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync();
        sync.update = true;
        sync.updateInput = true;
        sync.updatedInputSize = input.length;
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }

        const buffer = await new Promise<IntegerArray>((resolve, reject) => {
            this._handleInputRequestResolve = resolve;
            this._handleInputRequestReject = reject;
        });
        this._handleInputRequestResolve = undefined;
        this._handleInputRequestReject = undefined;

        buffer.set(input);
        sync.updatedInputWrittenAtomic = true;
        sync.notifyUpdatedInputWritten();
    }

    private async sendInputPointer(inputPointer: number) {
        const sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync();
        sync.update = true;
        sync.updateInputPointer = true;
        sync.updatedInputPointer = inputPointer;
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }
    }

    private async execUpdateSource(source: string, startAt: number, afterTerminateCallback: () => void) {
        if (this._handleUpdateSourceResolve) {
            throw new Error('Invalid state - execUpdateSource is alreay called');
        }

        let sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync();
        sync.update = true;
        sync.updateSource = true;
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }
        
        const wait = await new Promise<boolean>((resolve, reject) => {
            this._handleUpdateSourceResolve = resolve;
            this._handleUpdateSourceReject = reject;
        });
        this._handleUpdateSourceResolve = undefined;
        this._handleUpdateSourceReject = undefined;
        
        this.terminate();
        afterTerminateCallback();
        
        if (this._lastUpdatedInput == null) {
            throw new Error("Input is missing");
        }
        if (this._lastReceivedData == null) {
            throw new Error("Data is missing");
        }
        
        this.createWorkerAndSync(source, true, startAt, false, this._lastReceivedData.tape);
        
        sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync();
        sync.update = true;
        const action = this.sendAction(wait ? 'restart-wait' : 'restart-run');
        const sendTapePointer = this.sendTapePointer(this._lastReceivedData.tapePointer);
        const sendInput = this.sendInput(this._lastUpdatedInput);
        const sendInputPointer = this.sendInputPointer(this._lastReceivedData.inputPointer);
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }

        await Promise.all([action, sendTapePointer, sendInput, sendInputPointer]);
    }

    private async execQueryData() {
        const sync = this.getSyncOrThrow();
        await sync.mutex.lockAsync()
        sync.update = true;
        sync.dataRequest = true;
        if (sync.mutex.unlock()) {
            sync.notifyUpdate();
        }
    }

    private onMessageFormWorker(event: MessageEvent<FromWorkerMessage>) {
        const msg = event.data;
        switch (msg.type) {
            case 'data':
                this._lastReceivedData = msg.data;
                this.emitEvent([msg.data], this._dataReceivedListeners);
                break;
            case 'end':
                this.terminate();
                this.emitEvent([], this._finishedListeners);
                break;
            case 'pause':
                this.emitEvent([], this._waitingListeners);
                break;
            case 'error':
                this.terminate();
                this.emitEvent([msg.data.msg], this._errorListeners);
                break;
            case 'request':
                if (msg.data.target === 'input') {
                    this._handleInputRequestResolve?.(msg.data.buffer);
                }
                break;
            case 'updateSource':
                this._handleUpdateSourceResolve?.(msg.data.wait);
                break;
        }
    }

    private addListener<TCallback>(callback: TCallback, listeners: TCallback[]) {
        listeners.push(callback);
    }

    private removeListener<TCallback>(callback: TCallback, listeners: TCallback[]) {
        const index = listeners.indexOf(callback);
        if (index > -1) {
            listeners.splice(index, 1);
        }
    }

    private emitEvent<TCallback extends (...args: any[]) => any>(data: Parameters<TCallback>, listeners: TCallback[]) {
        for (const listener of listeners) {
            listener(...data);
        }
    }

    private getSyncOrThrow() {
        if (!this._workerSync) {
            throw new Error("Shared Buffer not exchanged");
        }
        return this._workerSync;
    }

    private sendMessageToWorker(message: ToWorkerMessage) {
        if (!this._worker) {
            throw new Error('No Worker is running');
        }
        this._worker.postMessage(message);
    }
}
