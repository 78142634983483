import { EditorView } from "codemirror";
import { StateField, StateEffect, Extension, RangeSet, Facet } from '@codemirror/state';
import { Decoration, DecorationSet, WidgetType } from '@codemirror/view';

export type ChangedRange = { from: number, to: number, charDiff: number };

type TrackedChanges = { showChanges: boolean, changes: ChangedRange[] };

type PositionConvertionResult = { type: 'exact' | 'near', position: number };

export const uncommittedChangesListener = Facet.define<(newUncommitedChanges: ChangedRange[], oldUncommitedChanges: ChangedRange[]) => void>();

const commitChangesEffect = StateEffect.define<void>();
const showChangesEffect = StateEffect.define<boolean>();

const changesField = StateField.define({
    create() {
        return { showChanges: false, changes: [] } as TrackedChanges;
    },
    update(value, transaction) {
        let changes = value.changes;
        let showChanges = value.showChanges;

        const setShowChanges = transaction.effects.findLast(effect => effect.is(showChangesEffect));
        if (setShowChanges) {
            showChanges = setShowChanges.value;
        }

        const commit = transaction.effects.some(effect => effect.is(commitChangesEffect));
        if (commit && changes.length > 0) {
            changes = [];
        }

        if (transaction.docChanged && !transaction.changes.empty) {
            let changesCloned = false;

            transaction.changes.iterChanges((fromOld, toOld, fromNew, toNew, insertedText) => {
                let toInsert: ChangedRange = {
                    from: fromOld,
                    to: toOld,
                    charDiff: fromOld - toOld + insertedText.length,
                }
                let mergeCharDiff = 0;
                let i = 0;
                while (i < changes.length && changes[i].to < toInsert.from) {
                    i++;
                }
                const toMergeStartIndexInc = i;
                while (i < changes.length && toInsert.to >= changes[i].from) {
                    mergeCharDiff += changes[i].charDiff;
                    i++;
                }
                const toMergeEndIndexExc = i;
                const mergeCount = toMergeEndIndexExc - toMergeStartIndexInc;
                if (mergeCount > 0) {
                    toInsert = {
                        from: Math.min(toInsert.from, changes[toMergeStartIndexInc].from),
                        to: Math.max(toInsert.to, changes[toMergeEndIndexExc - 1].to),
                        charDiff: mergeCharDiff + toInsert.charDiff,
                    };
                }
                if (!changesCloned) {
                    changesCloned = true;
                    changes = changes.slice();
                }
                changes.splice(toMergeStartIndexInc, mergeCount, toInsert);
            });

            for (let i = 0; i < changes.length; i++) {
                const change = changes[i];
                const newFrom = transaction.changes.mapPos(change.from, -1);
                const newTo = transaction.changes.mapPos(change.to, 1);
                if (newFrom === change.from && newTo === change.to) {
                    continue;
                }
                if (!changesCloned) {
                    changesCloned = true;
                    changes = changes.slice();
                }
                change.from = newFrom;
                change.to = newTo;
            }
        }
        
        if (value.changes !== changes) {
            const listeners = transaction.startState.facet(uncommittedChangesListener);
            for (const listener of listeners) {
                listener(changes, value.changes);
            }
        }

        return { showChanges, changes };
    },
    provide: f => EditorView.decorations.from(f, value => {
        if (!value.showChanges) {
            return Decoration.none;
        }
        const result = [];
        for (const change of value.changes) {
            if (change.from === change.to) {
                result.push(deleteChangesDecoration.range(change.from));
            } else {
                result.push(insertChangesDecoration.range(change.from, change.to));
            }
        }
        return RangeSet.of(result) as DecorationSet;
    }),
});


class DeleteChangesWidget extends WidgetType {
    constructor() {
        super();
    }

    eq(other: DeleteChangesWidget) {
        return true;
    }

    toDOM() {
        const el = document.createElement("span");
        el.className = "uncommitted-delete-wrapper";
        const el2 = el.appendChild(document.createElement("span"));
        el2.className = "uncommitted-delete";
        return el;
    }
}

const insertChangesDecoration = Decoration.mark({
    attributes: { class: "uncommitted-insert" },
});

const deleteChangesDecoration = Decoration.widget({
    widget: new DeleteChangesWidget(),
});

const changesTheme = EditorView.theme({
    ".uncommitted-insert": {
        backgroundColor: "#ff770077",
    },
    ".uncommitted-delete-wrapper": {
        height: "100%",
        width: "0px",
        position: "relative",
    },
    ".uncommitted-delete": {
        background: "#ff770077",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "-3px",
        width: "6px",
        zIndex: -1,
    },
    ".cm-activeLine": {
        zIndex: -1,
        position: "relative",
    },
});

export function uncommittedChanges(): Extension {
    return [
        changesField,
        changesTheme,
    ];
}

export function getUncommittedChanges(view: EditorView): ChangedRange[] {
    const value = view.state.field(changesField, false);
    return value?.changes ?? [];
}

export function commitChanges(view: EditorView) {
    view.dispatch({ effects: commitChangesEffect.of() });
}

export function isShowingChanges(view: EditorView) {
    const value = view.state.field(changesField, false);
    return value?.showChanges ?? false;
}

export function setShowingChanges(view: EditorView, showChanges: boolean) {
    view.dispatch({ effects: showChangesEffect.of(showChanges) });
}

export function toLineAndCharacter(view: EditorView, position: number | undefined) {
    if (position == null) {
        return undefined;
    }
    const line = view.state.doc.lineAt(position);
    const column = position - line.from;
    const char = line.text.substring(0, column + 1).replaceAll(/\s/g, '').length;
    return { line: line.number, character: char };
}

export function toPositionWithUncommittedChanges(changes: ChangedRange[], positionWithoutUncommittedChanges: number): PositionConvertionResult {
    if (changes == null) {
        return { type: 'exact', position: positionWithoutUncommittedChanges };
    }
    let mappedPosition = positionWithoutUncommittedChanges;
    for (const change of changes) {
        if (mappedPosition < change.from ) {
            break;
        }
        mappedPosition += change.charDiff;
        if (mappedPosition < change.to) {
            return { type: 'near', position: change.from };
        }
    }
    return { type: 'exact', position: mappedPosition };
}

export function toPositionWithoutUncommittedChanges(changes: ChangedRange[], positionWithUncommittedChanges: number): PositionConvertionResult {
    if (changes == null) {
        return { type: 'exact', position: positionWithUncommittedChanges };
    }
    let moveBack = 0;
    for (const change of changes) {
        if (positionWithUncommittedChanges < change.from) {
            break;
        }
        if (positionWithUncommittedChanges < change.to) {
            return { type: 'near', position: change.from - moveBack };
        }
        moveBack += change.charDiff;
    }
    return { type: 'exact', position: positionWithUncommittedChanges - moveBack };
}
