<template>
  <layout-default :title="vm.title" :actions="actions" :has-back-button="true" @back="$router.back()">
    <v-row v-if="vm.errorWhileSaving">
      <v-alert :text="vm.errorWhileSaving.toString()" type="error" variant="tonal"></v-alert>
    </v-row>
    <v-row justify="center">
      <bf-code-editor
        ref="codeEditor"
        v-model="vm.src"
        v-model:current-instruction="vm.currentInstruction"
        :show-changes="vm.duringRunState"
        :show-text-info="vm.showExtraInfo"
        @has-uncommitted-changes="vm.hasUncommittedChanges = $event">
      </bf-code-editor>
    </v-row>
    <v-row v-if="vm.state === 'error'">
      <v-alert :text="vm.errorMsg" type="error" variant="tonal"></v-alert>
    </v-row>
    <v-row>
      <v-sheet class="w-100 pa-4 my-2" color="grey-lighten-3" rounded>
        <v-row>
          <v-col class="d-flex flex-wrap justify-center ga-4">
            <!-- Reset -->
            <v-btn
              v-if="!vm.duringRunState"
              icon="mdi-restore"
              :disabled="vm.state === 'reset'"
              @click="vm.reset()"
              rounded>
            </v-btn>
            <!-- Run -->
            <v-btn
              v-if="!vm.duringRunState"
              icon="mdi-play"
              @click="vm.run()"
              rounded>
            </v-btn>
            <!-- Debug -->
            <v-btn
              v-if="!vm.duringRunState"
              icon="mdi-bug-play-outline"
              @click="vm.debug()"
              rounded>
            </v-btn>
            <!-- Stop -->
            <v-btn
              v-if="vm.duringRunState"
              icon="mdi-stop"
              @click="vm.stop()"
              rounded>
            </v-btn>
            <!-- Pause -->
            <v-btn
              v-if="vm.duringRunState"
              icon="mdi-pause"
              :disabled="vm.state !== 'running'"
              @click="vm.pause()"
              rounded>
            </v-btn>
            <!-- Continue -->
            <v-btn
              v-if="vm.duringRunState"
              icon="mdi-step-forward"
              :disabled="vm.state !== 'paused'"
              @click="vm.continue()"
              rounded>
            </v-btn>
            <!-- Run To End -->
            <v-btn
              v-if="vm.duringRunState && vm.debugging"
              icon="mdi-skip-forward"
              :disabled="vm.state !== 'paused'"
              @click="vm.continueWithoutBreakpoints()"
              rounded>
            </v-btn>
            <!-- Step -->
            <v-btn
              v-if="vm.duringRunState && vm.debugging"
              icon="mdi-debug-step-over"
              :disabled="vm.state !== 'paused'"
              @mousedown="vm.multiStepStart()"
              @touchstart="vm.multiStepStart()"
              @click="vm.multiStepStop(true)"
              @mouseup="vm.multiStepStop(false)"
              @mouseout="vm.multiStepStop(false)"
              @touchend="vm.multiStepStop(false)"
              @touchcancel="vm.multiStepStop(false)"
              rounded>
            </v-btn>
            <!-- Scroll To Current Instruction -->
            <v-btn
              icon="mdi-receipt-text-arrow-left-outline"
              :disabled="vm.state === 'reset'"
              @click="codeEditor().scrollToCodePointer()"
              rounded>
            </v-btn>
            <!-- Update Source -->
            <v-btn
              v-if="vm.duringRunState && vm.debugging"
              icon="mdi-receipt-text-check-outline"
              :disabled="!vm.hasUncommittedChanges"
              @click="vm.updateSource()"
              rounded>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="executionTextInfo?.length" dense>
          <v-col>
            <bf-text-info :model-value="executionTextInfo">
              <template v-if="nextInstructionAt" v-slot:value-next-instruction-at>
                {{ nextInstructionAt.type === 'near' ? 'around ' : '' }}Line
                <bf-grow-only class="text-right mr-1">{{ nextInstructionAt.line }}</bf-grow-only>
                Character
                <bf-grow-only class="text-right">{{ nextInstructionAt.character }}</bf-grow-only>
              </template>
            </bf-text-info>
          </v-col>
        </v-row>
      </v-sheet>
    </v-row>
    <v-row justify="center">
      <bf-text-area
        v-model="vm.input"
        label="Input"
        height="6rem"
        :text-info="inputTextInfo"
        :readonly-ranges="vm.inputReadonlyRanges">
      </bf-text-area>
    </v-row>
    <v-row>
      <bf-memory-viewer
        :tape="vm.tape"
        :tape-size="vm.settings.execution.tapeSize"
        :cell-size="vm.settings.execution.cellSize"
        :signed-cell="vm.settings.execution.signedCell"
        :active-cell="vm.tapePointer"
        :readonly="!vm.duringRunState || !vm.debugging"
        :show-text-info="vm.showExtraInfo"
        :string-converter="vm.stringConverter"
        @saved="vm.changeCellValue($event.cellIndex, $event.newCellValue, $event.isNewActiveCell)">
      </bf-memory-viewer>
    </v-row>
    <v-row justify="center">
      <bf-text-area
        :model-value="vm.output"
        label="Output"
        height="20rem"
        :text-info="outputTextInfo"
        readonly>
      </bf-text-area>
    </v-row>
  </layout-default>
</template>

<script lang="ts">
import { TextInfoEntry } from '@/components/bf-text-info/bf-text-info.vue';
import { Action } from '@/layouts/layout-default.vue';
import { defineComponent } from 'vue'
import EditorViewModel from './editor-view.vue.model';

export default defineComponent({
  name: 'editor-view',
  data() {
    return {
      vm: new EditorViewModel(),
      actions: [] as Action[],
    }
  },
  computed: {
    nextInstructionAt() {
      if (this.vm.currentInstruction == null) {
        return undefined;
      }
      const lineAndChar = this.codeEditor().getCurrentInstructionLineAndCharacterWithUncommittedChanges(this.vm.currentInstruction);
      return lineAndChar;
    },
    executionTextInfo(): TextInfoEntry[] {
      if (!this.vm.showExtraInfo) {
        return [];
      }
      let executedInstrsUnit;
      if (this.vm.executedInstructions) {
        executedInstrsUnit = this.vm.executedInstructions === 1 ? 'Instruction' : 'Instructions';
      }
      return [
        { id: 'next-instruction-at', label: 'Next Instruction at', combinedValueAndUnit: true },
        { label: 'Executed', value: this.vm.executedInstructions, unit: executedInstrsUnit },
        { label: 'Total Time', value: this.vm.runTime?.toFixed(2), unit: this.vm.runTime == null ? undefined : 'ms' },
        { label: 'Execution Time', value: this.vm.executionTime?.toFixed(2), unit: this.vm.executionTime == null ? undefined : 'ms' },
        { label: 'Execution Speed', value: this.vm.executionSpeed?.toFixed(0), unit: this.vm.executionSpeed == null ?  undefined : 'Instructions/ms' },
      ];
    },
    inputTextInfo(): TextInfoEntry[] {
      if (!this.vm.showExtraInfo) {
        return [];
      }
      const length = this.vm.inputCodeUnitLength;
      return [{ label: 'Length', value: length, unit: length === 1 ? 'Code Unit' : 'Code Units' }];
    },
    outputTextInfo(): TextInfoEntry[] {
      if (!this.vm.showExtraInfo) {
        return [];
      }
      const length = this.vm.outputCodeUnitLength;
      return [{ label: 'Length', value: length, unit: length === 1 ? 'Code Unit' : 'Code Units' }];
    },
  },
  async beforeMount() {
    await this.vm.initialize(this.$route.params.id);
    this.vm.getCurrentInstructionWithUncommittedChanges = (currentInstruction, near) => this.codeEditor().getCurrentInstructionWithUncommittedChanges(currentInstruction, near);
    this.vm.commitChanges = () => this.codeEditor().commitChanges();
    this.actions = [
      {
        id: 'show-more-info',
        icon: 'mdi-information-slab-box-outline',
        iconOn: 'mdi-information-slab-box',
        type: 'toggle',
        location: 'bar',
        currentValue: this.vm.showExtraInfo,
        onClick: (on: boolean) => { this.vm.showExtraInfo = on},
      },
      {
        id: 'indent-more',
        icon: 'mdi-format-indent-increase',
        type: 'button',
        location: 'bar',
        onClick: () => this.codeEditor().indentSelectionMore(),
      },
      {
        id: 'indent-less',
        icon: 'mdi-format-indent-decrease',
        type: 'button',
        location: 'bar',
        onClick: () => this.codeEditor().indentSelectionLess(),
      },
      {
        id: 'settings',
        icon: 'mdi-cog',
        title: 'Settings',
        type: 'button',
        location: 'menu',
        onClick: () => this.$router.push({ name: 'settings' }),
      }
    ];
  },
  async unmounted() {
    await this.vm.uninitialize();
  },
  methods: {
    codeEditor() {
      return this.$refs.codeEditor as any;
    },
  },
})
</script>
