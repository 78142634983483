import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d189c54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-label pr-2" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = {
  key: 0,
  class: "info-unit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_label = _resolveComponent("v-label")!
  const _component_bf_grow_only = _resolveComponent("bf-grow-only")!

  return (_openBlock(), _createElementBlock("table", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: item.label,
        class: _normalizeClass(['info-entry', item.class])
      }, [
        _createElementVNode("td", _hoisted_1, [
          _createVNode(_component_v_label, { class: "value-label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _createElementVNode("td", {
          ref_for: true,
          ref: "textInfoValue",
          class: _normalizeClass({ 'info-value': true, 'text-right': !item.combinedValueAndUnit }),
          colspan: item.combinedValueAndUnit ? 2 : undefined
        }, [
          _renderSlot(_ctx.$slots, `value-${item.id}`, {}, () => [
            _createVNode(_component_bf_grow_only, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.value), 1)
              ]),
              _: 2
            }, 1024)
          ], true)
        ], 10, _hoisted_2),
        (item.unit && !item.combinedValueAndUnit)
          ? (_openBlock(), _createElementBlock("td", _hoisted_3, [
              _renderSlot(_ctx.$slots, `unit-${item.id}`, {}, () => [
                _createTextVNode(_toDisplayString(item.unit), 1)
              ], true)
            ]))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ]))
}