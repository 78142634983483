import Mutex from "./mutex";

export type Action = 'run' | 'restart-run' | 'restart-wait' | 'continue' | 'continue-without-breakpoints' | 'single-step' | 'pause' | 'stop';

export default class Sync {

    private static readonly INDEX_MUTEX = 0;
    public static readonly INDEX_UPDATE = 1;
    private static readonly INDEX_DATA_REQUEST = 2;
    private static readonly INDEX_ACTION = 3;
    private static readonly INDEX_UPDATE_INPUT = 4;
    private static readonly INDEX_UPDATED_INPUT_SIZE = 5;
    private static readonly INDEX_UPDATED_INPUT_WRITTEN = 6;
    private static readonly INDEX_UPDATE_INPUT_POINTER = 7;
    private static readonly INDEX_UPDATED_INPUT_POINTER = 8;
    private static readonly INDEX_UPDATE_TAPE_CELL = 9;
    private static readonly INDEX_UPDATE_TAPE_CELL_INDEX = 10;
    private static readonly INDEX_UPDATED_TAPE_CELL_VALUE = 11;
    private static readonly INDEX_UPDATE_TAPE_POINTER = 12;
    private static readonly INDEX_UPDATED_TAPE_POINTER = 13;
    private static readonly INDEX_UPDATE_SOURCE = 14;
    private static readonly BUFFER_LENGTH = 15;
    private static readonly VALUE_ACTION_NO_CHANGE = 0;
    private static readonly VALUE_ACTION_RUN = 1;
    private static readonly VALUE_ACTION_RESTART_RUN = 2;
    private static readonly VALUE_ACTION_RESTART_WAIT = 3;
    private static readonly VALUE_ACTION_CONTINUE = 4;
    private static readonly VALUE_ACTION_CONTINUE_WITHOUT_BREAKPOINTS = 5;
    private static readonly VALUE_ACTION_SINGLE_STEP = 6;
    private static readonly VALUE_ACTION_PAUSE = 7;
    private static readonly VALUE_ACTION_STOP = 8;

    private _buffer: Int32Array;
    private _mutex: Mutex;

    public constructor(buffer?: Int32Array) {
        this._buffer = buffer ?? new Int32Array(new SharedArrayBuffer(Sync.BUFFER_LENGTH * Int32Array.BYTES_PER_ELEMENT));
        this._mutex = newMutex(this._buffer, Sync.INDEX_MUTEX);
    }

    public get buffer(): Int32Array {
        return this._buffer;
    }

    public get mutex(): Mutex {
        return this._mutex;
    }

    public get update(): boolean {
        return this._buffer[Sync.INDEX_UPDATE] === 1;
    }

    public set update(value: boolean) {
        this._buffer[Sync.INDEX_UPDATE] = value ? 1 : 0;
    }

    public get dataRequest(): boolean {
        return this._buffer[Sync.INDEX_DATA_REQUEST] === 1;
    }

    public set dataRequest(value: boolean) {
        this._buffer[Sync.INDEX_DATA_REQUEST] = value ? 1 : 0;
    }

    public get action(): Action | undefined {
        const action = this._buffer[Sync.INDEX_ACTION];
        switch (action) {
            case Sync.VALUE_ACTION_RUN:
                return 'run';
            case Sync.VALUE_ACTION_RESTART_RUN:
                return 'restart-run';
            case Sync.VALUE_ACTION_RESTART_WAIT:
                return 'restart-wait';
            case Sync.VALUE_ACTION_CONTINUE:
                return 'continue';
            case Sync.VALUE_ACTION_CONTINUE_WITHOUT_BREAKPOINTS:
                return 'continue-without-breakpoints';
            case Sync.VALUE_ACTION_SINGLE_STEP:
                return 'single-step';
            case Sync.VALUE_ACTION_PAUSE:
                return 'pause';
            case Sync.VALUE_ACTION_STOP:
                return 'stop';
            default:
                return undefined;
        }
    }

    public set action(value: Action | undefined) {
        let newAction: number;
        switch (value) {
            case 'run':
                newAction = Sync.VALUE_ACTION_RUN;
                break;
            case 'restart-run':
                newAction = Sync.VALUE_ACTION_RESTART_RUN;
                break;
            case 'restart-wait':
                newAction = Sync.VALUE_ACTION_RESTART_WAIT;
                break;
            case 'continue':
                newAction = Sync.VALUE_ACTION_CONTINUE;
                break;
            case 'continue-without-breakpoints':
                newAction = Sync.VALUE_ACTION_CONTINUE_WITHOUT_BREAKPOINTS;
                break;
            case 'single-step':
                newAction = Sync.VALUE_ACTION_SINGLE_STEP;
                break;
            case 'pause':
                newAction = Sync.VALUE_ACTION_PAUSE;
                break;
            case 'stop':
                newAction = Sync.VALUE_ACTION_STOP;
                break;
            default:
                newAction = Sync.VALUE_ACTION_NO_CHANGE;
                break;
        }
        this._buffer[Sync.INDEX_ACTION] = newAction;
    }

    public get updateInput(): boolean {
        return this._buffer[Sync.INDEX_UPDATE_INPUT] === 1;
    }

    public set updateInput(value: boolean) {
        this._buffer[Sync.INDEX_UPDATE_INPUT] = value ? 1 : 0;
    }

    public get updatedInputSize(): number {
        return this._buffer[Sync.INDEX_UPDATED_INPUT_SIZE];
    }

    public set updatedInputSize(value: number) {
        this._buffer[Sync.INDEX_UPDATED_INPUT_SIZE] = value;
    }

    public get updatedInputWrittenAtomic(): boolean {
        return Atomics.load(this._buffer, Sync.INDEX_UPDATED_INPUT_WRITTEN) === 1;
    }

    public set updatedInputWrittenAtomic(value: boolean) {
        Atomics.store(this._buffer, Sync.INDEX_UPDATED_INPUT_WRITTEN, value ? 1 : 0);
    }

    public get updateInputPointer(): boolean {
        return this._buffer[Sync.INDEX_UPDATE_INPUT_POINTER] === 1;
    }

    public set updateInputPointer(value: boolean) {
        this._buffer[Sync.INDEX_UPDATE_INPUT_POINTER] = value ? 1 : 0;
    }

    public get updatedInputPointer(): number {
        return this._buffer[Sync.INDEX_UPDATED_INPUT_POINTER];
    }

    public set updatedInputPointer(value: number) {
        this._buffer[Sync.INDEX_UPDATED_INPUT_POINTER] = value;
    }

    public get updateTapeCell(): boolean {
        return this._buffer[Sync.INDEX_UPDATE_TAPE_CELL] === 1;
    }

    public set updateTapeCell(value: boolean) {
        this._buffer[Sync.INDEX_UPDATE_TAPE_CELL] = value ? 1 : 0;
    }

    public get updateTapeCellIndex(): number {
        return this._buffer[Sync.INDEX_UPDATE_TAPE_CELL_INDEX];
    }

    public set updateTapeCellIndex(value: number) {
        this._buffer[Sync.INDEX_UPDATE_TAPE_CELL_INDEX] = value;
    }

    public get updatedTapeCell(): number {
        return this._buffer[Sync.INDEX_UPDATED_TAPE_CELL_VALUE];
    }

    public set updatedTapeCell(value: number) {
        this._buffer[Sync.INDEX_UPDATED_TAPE_CELL_VALUE] = value;
    }

    public get updateTapePointer(): boolean {
        return this._buffer[Sync.INDEX_UPDATE_TAPE_POINTER] === 1;
    }

    public set updateTapePointer(value: boolean) {
        this._buffer[Sync.INDEX_UPDATE_TAPE_POINTER] = value ? 1 : 0;
    }

    public get updatedTapePointer(): number {
        return this._buffer[Sync.INDEX_UPDATED_TAPE_POINTER];
    }

    public set updatedTapePointer(value: number) {
        this._buffer[Sync.INDEX_UPDATED_TAPE_POINTER] = value;
    }

    public get updateSource(): boolean {
        return this._buffer[Sync.INDEX_UPDATE_SOURCE] === 1;
    }

    public set updateSource(value: boolean) {
        this._buffer[Sync.INDEX_UPDATE_SOURCE] = value ? 1 : 0;
    }

    public notifyUpdate() {
        Atomics.notify(this._buffer, Sync.INDEX_UPDATE);
    }

    public waitForUpdate() {
        Atomics.wait(this._buffer, Sync.INDEX_UPDATE, 0);
    }

    public notifyUpdatedInputWritten() {
        Atomics.notify(this._buffer, Sync.INDEX_UPDATED_INPUT_WRITTEN);
    }

    public waitForUpdatedInputWritten() {
        Atomics.wait(this._buffer, Sync.INDEX_UPDATED_INPUT_WRITTEN, 0);
    }
}

declare global {
    // eslint-disable-next-line no-var
    var newSync: (buffer?: Int32Array) => Sync;
}

globalThis.newSync = (buffer?: Int32Array) => new Sync(buffer);

export const syncCode = Sync.toString() + `newSync=${newSync};`;
