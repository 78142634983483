import { ExecSettings } from "../file";
import Sync from "../synchronization/sync";
import { getWorkerCode } from "./worker-preset";

type AnonymousInstr = { originalCount: number, sourceIndex: number };
type AnonymousCombinedInstr = { repeat: number, sourceIndices: number[] } & AnonymousInstr;
type AnonymousReversableInstr = { reverse: boolean } & AnonymousInstr;
type AnonymousGroupedInstr = { loopInstrs: Instrs, originalCountPerLoop: number, sourceIndexOfLoopEnd: number, firstIterUnrolled: boolean } & AnonymousInstr;
type IncInstr = { id: 'inc' } & AnonymousCombinedInstr & AnonymousReversableInstr;
type MoveInstr = { id: 'move' } & AnonymousCombinedInstr & AnonymousReversableInstr;
type PrintInstr = { id: 'print' } & AnonymousCombinedInstr;
type ReadInstr = { id: 'read' } & AnonymousCombinedInstr;
type LoopInstr = { id: 'loop' } & AnonymousGroupedInstr;
type DebuggerInstr = { id: 'debugger' } & AnonymousInstr;
type Instr = IncInstr | MoveInstr | PrintInstr | ReadInstr | LoopInstr | DebuggerInstr;
type Instrs = Instr[];
type SingleStepInstr = 'inc' | 'dec' | 'moveRight' | 'moveLeft' | 'print' | 'read';
type GeneratedCode = { current: string, functions?: string, usedInstrs?: SingleStepInstr[] };

type AllowStringSourceIndices<T> = Omit<T, 'sourceIndex' | 'sourceIndices'> & { sourceIndex: number | string, sourceIndices: (number | string)[] }

export default class BrainfuckCompiler {

    private static readonly MAX_INSTRUCTIONS_WITHOUT_UPDATE_CHECK = 10000;

    private _settings: ExecSettings;
    private _counter: number = 0;

    constructor(settings: ExecSettings) {
        this._settings = settings;
    }

    public compile(source: string, debuggable: boolean, startAt: number = 0) {
        if (!debuggable && startAt) {
            throw new Error("StartAt property only allowed when debugging");
        }
        const { instrs, startSourceIndex } = this.genSyntaxTree(source, debuggable, startAt);
        return this.buildWorker(instrs, startSourceIndex, debuggable);
    }

    private genSyntaxTree(source: string, debuggable: boolean, startAt: number = 0): { instrs: Instrs, startSourceIndex: number | undefined } {
        const instrsMeta = this.genSyntaxTreeRecursive(source, 0, debuggable);
        if (!instrsMeta.finished) {
            throw new Error("Bracket mismatch: Unexpected ']'");
        }
        let instrs = instrsMeta.instrs;
        if (startAt) {
            instrs = this.modifyToStartAt(instrs, startAt);
        }
        let startSourceIndex: number | undefined;
        if (instrs.length) {
            const instr = instrs[0];
            startSourceIndex = instr.id === 'loop' && instr.firstIterUnrolled ? instr.sourceIndexOfLoopEnd : instr.sourceIndex;
        }
        return { instrs, startSourceIndex: startSourceIndex };
    }

    private genSyntaxTreeRecursive(source: string, start: number, debuggable: boolean): { finished: boolean, end: number, instrs: Instrs } {
        const instrs: Instrs = [];
        for (let i = start; i < source.length; i++) {
            const char = source[i];
            if (char !== '+'
                    && char !== '-'
                    && char !== '>'
                    && char !== '<'
                    && char !== '.'
                    && char !== ','
                    && char !== '['
                    && char !== ']'
                    && (!debuggable || char !== '#')) {
                continue;
            }
            if (char === '#') {
                instrs.push({ id: 'debugger', originalCount: 0, sourceIndex: i });
                continue;
            }
            if (char === '[') {
                const loop = this.genSyntaxTreeRecursive(source, i + 1, debuggable);
                if (loop.finished) {
                    throw new Error("Bracket mismatch: Missing ']'");
                }
                instrs.push({
                    id: 'loop',
                    originalCount: 1,
                    originalCountPerLoop: 1,
                    sourceIndex: i,
                    sourceIndexOfLoopEnd: loop.end - 1,
                    loopInstrs: loop.instrs,
                    firstIterUnrolled: false
                });
                i = loop.end - 1;
                continue;
            } else if (char === ']') {
                return { finished: false, end: i + 1, instrs };
            }
            let count = 1;
            const indices = [i];
            for (i++; i < source.length; i++) {
                const char2 = source[i];
                if (char === char2) {
                    count++;
                    indices.push(i);
                    continue;
                }
                if (char2 === '+'
                        || char2 === '-'
                        || char2 === '>'
                        || char2 === '<'
                        || char2 === '.'
                        || char2 === ','
                        || char2 === '['
                        || char2 === ']'
                        || (debuggable && char2 === '#')) {
                    break;
                }
            }
            i--;
            let instr: ('inc' | 'move' | 'print' | 'read') & Instr['id'];
            let reverse: boolean | undefined = undefined;
            switch (char) {
                case '+':
                case '-':
                    instr = 'inc';
                    reverse = char === '-';
                    break;
                case '>':
                case '<':
                    instr = 'move';
                    reverse = char === '<';
                    break;
                case '.':
                    instr = 'print';
                    break;
                case ',':
                    instr = 'read';
                    break;
            }
            instrs.push({
                id: instr,
                originalCount: count,
                sourceIndex: indices[0],
                reverse: reverse!,
                repeat: count,
                sourceIndices: indices
            });
        }
        return { finished: true, end: source.length, instrs };
    }

    private modifyToStartAt(instrs: Instrs, startAt: number): Instrs {
        let is = this.removeInstrsBeforeStart(instrs, startAt);
        if (is.length === 0) {
            return is;
        }
        while (is[0].sourceIndex !== startAt && is[0].id === 'loop') {
            const unrolled = this.removeInstrsBeforeStart(is[0].loopInstrs, startAt);
            is[0] = { ...is[0], firstIterUnrolled: true };
            if (unrolled.length === 0) {
                break;
            }
            unrolled.push(...is);
            is = unrolled;
        }
        return is;
    }

    private removeInstrsBeforeStart(instrs: Instrs, startAt: number): Instrs {
        for (let i = 0; i < instrs.length; i++) {
            const instr = instrs[i];
            if (instr.id === 'inc' || instr.id === 'move' || instr.id === 'print' || instr.id === 'read') {
                const lastIndex = instr.sourceIndices.at(-1);
                if (lastIndex == null) {
                    throw new Error('Source Indices are empty');
                }
                if (lastIndex >= startAt) {
                    for (let j = 0; j < instr.sourceIndices.length; j++) {
                        if (instr.sourceIndices[j] >= startAt) {
                            const newInstrs = instrs.slice(i);
                            const newSourceIndices = instr.sourceIndices.slice(j);
                            newInstrs[0] = {
                                ...instr,
                                sourceIndex: newSourceIndices[0],
                                sourceIndices: newSourceIndices,
                                originalCount: newSourceIndices.length,
                                repeat: newSourceIndices.length
                            };
                            return newInstrs;
                        }
                    }
                    throw new Error('Unreachable');
                }
            } else if (instr.id === 'loop') {
                if (instr.sourceIndexOfLoopEnd >= startAt) {
                    return instrs.slice(i);
                }
            } else if (instr.id === 'debugger') {
                if (instr.sourceIndex >= startAt) {
                    return instrs.slice(i);
                }
            } else {
                throw new Error('Unreachable');
            }
        }
        return [];
    }

    private buildWorker(instrs: Instrs, startSourceIndex: number | undefined, debuggable: boolean): string {
        const generated = this.instrsToJs(instrs, undefined, 0, debuggable);
        let funcs = generated.functions ?? "";
        if (generated.usedInstrs) {
            funcs += this.generateSingleStepFunctions(generated.usedInstrs);
        }
        return getWorkerCode()
            .replace('__IS_DEBUG__', debuggable.toString())
            .replace('__ARRAY_TYPE__', this.arrayTypeJs())
            .replace('__INITIAL_TAPE_SIZE__', this._settings.tapeSize.toString())
            .replace('__RUN__', `0;${generated.current}0`)
            .replace('__FUNCS__', funcs)
            .replace('__START_CODE_POINTER__', `${startSourceIndex}`);
    }

    private arrayTypeJs(): string {
        const cellSize = this._settings.cellSize;
        let bitSize;
        if (cellSize <= 2**8) {
            bitSize = 8;
        } else if (cellSize <= 2**16) {
            bitSize = 16;
        } else if (cellSize <= 2**32) {
            bitSize = 32;
        } else {
            throw new Error("Cell size too big");
        }
        return `Uint${bitSize}Array`;
    }

    private processUpdateJs(codePointer: number | undefined, executedInstructions: number) {
        let result = "";
        result += `this._executedInstrCountBase+=${executedInstructions};`;
        result += `if(this._syncBuffer[${Sync.INDEX_UPDATE}]===1){`; // same as: if (this._sync.update) { ... }
        result += `this.processUpdate(${codePointer});`;
        result += "}";
        return result;
    }

    private instrsToJs(instrs: Instrs, lastSourceIndex: number | undefined, beginInstrCount: number, debuggable: boolean): GeneratedCode {
        let current = "";
        let functions = "";
        let usedInstrs: SingleStepInstr[] = [];
        let instrsCount = beginInstrCount;
        for (const instr of instrs) {
            if (instr.id === 'loop' || instrsCount >= BrainfuckCompiler.MAX_INSTRUCTIONS_WITHOUT_UPDATE_CHECK) {
                const srcIndex = instr.id === 'loop' && instr.firstIterUnrolled ? instr.sourceIndexOfLoopEnd : instr.sourceIndex;
                current += this.processUpdateJs(srcIndex, instrsCount);
                instrsCount = 0;
            }
            const generatedInstr = this.instrToJs(instr, debuggable, instrsCount);
            current += generatedInstr.current;
            functions += (generatedInstr.functions ?? "");
            if (generatedInstr.usedInstrs) {
                usedInstrs = [...new Set([...usedInstrs, ...generatedInstr.usedInstrs])];
            }
            instrsCount += instr.originalCount;
        }
        if (instrsCount !== 0) {
            current += this.processUpdateJs(lastSourceIndex, instrsCount);
        }
        return { current, functions, usedInstrs };
    }

    private instrToJs(instr: Instr, debuggable: boolean, executedInstructions: number): GeneratedCode {
        let generated: GeneratedCode;
        switch (instr.id) {
            case 'inc':
                generated = this.incInstrToJs(instr, debuggable, executedInstructions);
                break;
            case 'move':
                generated = this.moveInstrToJs(instr, debuggable, executedInstructions);
                break;
            case 'print':
                generated = this.printInstrToJs(instr, debuggable, executedInstructions);
                break;
            case 'read':
                generated = this.readInstrToJs(instr, debuggable, executedInstructions);
                break;
            case 'loop':
                generated = this.loopInstrToJs(instr, debuggable, executedInstructions);
                break;
            case 'debugger':
                generated = this.breakpointInstrToJs(instr, debuggable, executedInstructions);
                break;
        }
        return generated;
    }

    private regularInstrToJs(instr: AnonymousCombinedInstr, singleStepInstr: SingleStepInstr, instrJs: string, debuggable: boolean, executedInstructions: number): GeneratedCode {
        if (debuggable) {
            let resultDebug = "if(this._singleStep){";
            resultDebug += `this.bf_${singleStepInstr}SingleStep(${instr.repeat},[${instr.sourceIndices}],${executedInstructions});`;
            resultDebug += "}else{";
            resultDebug += instrJs;
            resultDebug += "}";
            return { current: resultDebug, usedInstrs: [singleStepInstr] };
        } else {
            return { current: instrJs };
        }
    }

    private incInstrToJs(instr: IncInstr, debuggable: boolean, executedInstructions: number): GeneratedCode {
        return this.regularInstrToJs(instr, instr.reverse ? 'dec' : 'inc', this.incLogicJs(instr, executedInstructions), debuggable, executedInstructions);
    }

    private moveInstrToJs(instr: MoveInstr, debuggable: boolean, executedInstructions: number): GeneratedCode {
        return this.regularInstrToJs(instr, instr.reverse ? 'moveLeft' : 'moveRight', this.moveLogicJs(instr, executedInstructions), debuggable, executedInstructions);
    }

    private printInstrToJs(instr: PrintInstr, debuggable: boolean, executedInstructions: number): GeneratedCode {
        return this.regularInstrToJs(instr, 'print', this.printLogicJs(instr, executedInstructions), debuggable, executedInstructions);
    }

    private readInstrToJs(instr: ReadInstr, debuggable: boolean, executedInstructions: number): GeneratedCode {
        return this.regularInstrToJs(instr, 'read', this.readLogicJs(instr, executedInstructions), debuggable, executedInstructions);
    }

    private loopInstrToJsInternal(instr: LoopInstr, loopBody: string, debuggable: boolean): string {
        let result = "";
        if (debuggable) {
            result += `if(this._singleStep){this.wait(${instr.firstIterUnrolled ? instr.sourceIndexOfLoopEnd : instr.sourceIndex},0);}`;
        }
        result += "while(this._tape[this._tapePointer]!==0){";
        result += loopBody;
        if (debuggable) {
            result += `if(this._singleStep){this.wait(${instr.sourceIndexOfLoopEnd},0);}`;
        }
        result += "}";
        return result;
    }

    private loopInstrToJs(instr: LoopInstr, debuggable: boolean, executedInstructions: number): GeneratedCode {
        if (executedInstructions !== 0) {
            throw new Error('Executed instructions befor loop is not zero');
        }
        
        const generatedInstrs = this.instrsToJs(instr.loopInstrs, instr.sourceIndexOfLoopEnd, instr.originalCountPerLoop, debuggable);

        let loopBody = "";
        let genFunction = "";
        if (!instr.loopInstrs.some(i => i.id === 'loop')) {
            loopBody = generatedInstrs.current;
        } else {
            const counter = this._counter++;
            const funcName = `bf_${counter}`;
            genFunction = `${funcName}(){${generatedInstrs.current}}`;
            loopBody += `this.${funcName}();`;
        }

        return {
            current: this.loopInstrToJsInternal(instr, loopBody, debuggable),
            functions: (generatedInstrs.functions ?? "") + genFunction,
            usedInstrs: generatedInstrs.usedInstrs,
        };
    }

    private breakpointInstrToJs(instr: DebuggerInstr, debuggable: boolean, executedInstructions: number): GeneratedCode {
        return { current: debuggable ? `if(!this._skipBreakpoints){this.wait(${instr.sourceIndex},${executedInstructions});}` : "" };
    }

    private generateSingleStepFunctions(usedInstrs: SingleStepInstr[]): string {
        let result = "";
        for (const instr of new Set(usedInstrs)) {
            switch (instr) {
                case 'inc':
                case 'dec':
                    result += this.singleStepIncInstrToJs(instr);
                    break;
                case 'moveRight':
                case 'moveLeft':
                    result += this.singleStepMoveInstrToJs(instr);
                    break;
                case 'print':
                    result += this.singleStepPrintInstrToJs();
                    break;
                case 'read':
                    result += this.singleStepReadInstrToJs();
                    break;
            }
        }
        return result;
    }

    private regularSingleStepInstrToJs(instr: SingleStepInstr, instrJs: (codePointer: string, executedInstructions: string) => string): string {
        let result = `bf_${instr}SingleStep(count,codePointers,executedInstrs){`;
        result += "for(let i=0;i<count;i++){";
        result += `const executedInstrsI=executedInstrs+i;`
        result += "if(this._singleStep){";
        result += "this.wait(codePointers[i],executedInstrsI);";
        result += "}";
        result += instrJs("codePointers[i]", "executedInstrsI");
        result += "}";
        result += "}";
        return result;
    }

    private singleStepIncInstrToJs(instr: 'inc' | 'dec'): string {
        return this.regularSingleStepInstrToJs(instr, (codePointer: string, executedInstructions: string) => this.incLogicJs({
            originalCount: 1,
            repeat: 1,
            reverse: instr === 'dec',
            sourceIndex: codePointer,
            sourceIndices: [codePointer],
        }, executedInstructions));
    }

    private singleStepMoveInstrToJs(instr: 'moveRight' | 'moveLeft'): string {
        return this.regularSingleStepInstrToJs(instr, (codePointer: string, executedInstructions: string) => this.moveLogicJs({
            originalCount: 1,
            repeat: 1,
            reverse: instr === 'moveLeft',
            sourceIndex: codePointer,
            sourceIndices: [codePointer],
        }, executedInstructions));
    }

    private singleStepPrintInstrToJs(): string {
        return this.regularSingleStepInstrToJs('print', (codePointer: string, executedInstructions: string) => this.printLogicJs({
            originalCount: 1,
            repeat: 1,
            sourceIndex: codePointer,
            sourceIndices: [codePointer],
        }, executedInstructions));
    }

    private singleStepReadInstrToJs(): string {
        return this.regularSingleStepInstrToJs('read', (codePointer: string, executedInstructions: string) => this.readLogicJs({
            originalCount: 1,
            repeat: 1,
            sourceIndex: codePointer,
            sourceIndices: [codePointer],
        }, executedInstructions));
    }

    private incLogicJs(instr: AllowStringSourceIndices<AnonymousCombinedInstr & AnonymousReversableInstr>, executedInstructions: number | string): string {
        const value = instr.repeat * (instr.reverse ? -1 : 1);
        const cellSize = this._settings.cellSize;
        const maxValue = this._settings.signedCell ? Math.ceil(cellSize / 2) - 1 : cellSize - 1;
        const minValue = this._settings.signedCell ? -Math.floor(cellSize / 2) : 0;
        switch (this._settings.cellOverflow) {
            case 'wrap':
                if (cellSize === 2**8 || cellSize === 2**16 || cellSize === 2**32) {
                    return `this._tape[this._tapePointer]+=${((value % cellSize) + cellSize) % cellSize};`;
                } else {
                    return `this._tape[this._tapePointer]=(this._tape[this._tapePointer]+${((value % cellSize) + cellSize) % cellSize})%${cellSize};`;
                }
            case 'ignore':
                if (this._settings.signedCell) {
                    let result = "";
                    if (value > 0) {
                        if (maxValue - value >= 0) {
                            result += `if(this._tape[this._tapePointer]>${maxValue - value}&&this._tape[this._tapePointer]<${cellSize + minValue}){`;
                        } else {
                            result += `if(this._tape[this._tapePointer]>${cellSize + maxValue - value}||this._tape[this._tapePointer]<${cellSize + minValue}){`;
                        }
                        result += `this._tape[this._tapePointer]=${maxValue};`;
                    } else {
                        if (minValue - value < 0) {
                            result += `if(this._tape[this._tapePointer]<${cellSize + minValue - value}&&this._tape[this._tapePointer]>${maxValue}){`;
                        } else {
                            result += `if(this._tape[this._tapePointer]<${minValue - value}||this._tape[this._tapePointer]>${maxValue}){`;
                        }
                        result += `this._tape[this._tapePointer]=${cellSize + minValue};`;
                    }
                    result += "}else{";
                    result += `this._tape[this._tapePointer]=(this._tape[this._tapePointer]+${((value % cellSize) + cellSize) % cellSize})%${cellSize};`;
                    result += "}";
                    return result;
                } else {
                    if (value > 0) {
                        return `this._tape[this._tapePointer]=Math.min(this._tape[this._tapePointer]+${value},${maxValue});`;
                    } else {
                        return `this._tape[this._tapePointer]=Math.max(this._tape[this._tapePointer]-${-value},${minValue});`;
                    }
                }
            case 'throw': {
                let result = "";
                if (value > 0) {
                    if (this._settings.signedCell) {
                        if (maxValue - value >= 0) {
                            result += `if(this._tape[this._tapePointer]>${maxValue - value}&&this._tape[this._tapePointer]<${cellSize + minValue}){`;
                        } else {
                            result += `if(this._tape[this._tapePointer]>${cellSize + maxValue - value}||this._tape[this._tapePointer]<${cellSize + minValue}){`;
                        }
                    } else {
                        result += `if(this._tape[this._tapePointer]>${maxValue - value}){`;
                    }
                    result += `const at=(${cellSize + maxValue}-this._tape[this._tapePointer])%${cellSize};`;
                    result += `this._tape[this._tapePointer]=${maxValue};`;
                    result += `this.sendErrorCellOverflow([${instr.sourceIndices}][at],${executedInstructions}+at);`;
                } else {
                    if (this._settings.signedCell) {
                        if (minValue - value < 0) {
                            result += `if(this._tape[this._tapePointer]<${cellSize + minValue - value}&&this._tape[this._tapePointer]>${maxValue}){`;
                        } else {
                            result += `if(this._tape[this._tapePointer]<${minValue - value}||this._tape[this._tapePointer]>${maxValue}){`;
                        }
                    } else {
                        result += `if(this._tape[this._tapePointer]<${minValue - value}){`;
                    }
                    result += `const at=(${cellSize + minValue}+this._tape[this._tapePointer])%${cellSize};`;
                    result += `this._tape[this._tapePointer]=${(cellSize + minValue) % cellSize};`;
                    result += `this.sendErrorCellUnderflow([${instr.sourceIndices}][at],${executedInstructions}+at);`;
                }
                result += "return;";
                result += "}";
                result += `this._tape[this._tapePointer]=(this._tape[this._tapePointer]+${((value % cellSize) + cellSize) % cellSize})%${cellSize};`;
                return result;
            }
        }
    }

    private moveLogicJs(instr: AllowStringSourceIndices<AnonymousCombinedInstr & AnonymousReversableInstr>, executedInstructions: number | string): string {
        const value = instr.repeat * (instr.reverse ? -1 : 1);
        const tapeSize = this._settings.tapeSize;
        switch (this._settings.tapeOverflow) {
            case 'wrap':
                return `this._tapePointer=(this._tapePointer+${((value % tapeSize) + tapeSize) % tapeSize})%${tapeSize};`;
            case 'ignore':
                if (value > 0) {
                    return `this._tapePointer=Math.min(this._tapePointer+${value},${tapeSize - 1});`;
                } else {
                    return `this._tapePointer=Math.max(this._tapePointer-${-value},0);`;
                }
            case 'throw':
                if (value > 0) {
                    let result = `if(this._tapePointer>=${tapeSize - value}){`;
                    result += `const at=${tapeSize - 1}-this._tapePointer;`;
                    result += `this._tapePointer=${tapeSize - 1};`;
                    result += `this.sendErrorTapeOverflow([${instr.sourceIndices}][at],${executedInstructions}+at);`;
                    result += "return;";
                    result += "}";
                    result += `this._tapePointer+=${value};`;
                    return result;
                } else {
                    let result = `if(this._tapePointer<${-value}){`;
                    result += `const at=this._tapePointer;`;
                    result += "this._tapePointer=0;";
                    result += `this.sendErrorTapeUnderflow([${instr.sourceIndices}][at],${executedInstructions}+at);`;
                    result += "return;";
                    result += "}";
                    result += `this._tapePointer-=${-value};`;
                    return result;
                }
        }
    }

    private printLogicJs(instr: AllowStringSourceIndices<AnonymousCombinedInstr>, executedInstructions: number | string): string {
        let result = "this._output.push(";
        result += Array(instr.repeat).fill("this._tape[this._tapePointer]").join(",");
        result += ");";
        return result;
    }

    private readLogicJs(instr: AllowStringSourceIndices<AnonymousCombinedInstr>, executedInstructions: number | string): string {
        const plusValueMinusOne = instr.repeat >= 2 ? "+" + (instr.repeat - 1) : "";
        let result = `if(this._inputPointer${plusValueMinusOne}>=this._input.length){`;
        if (this._settings.eof === 'noChange') {
            if (instr.repeat >= 2) {
                result += `if(this._inputPointer<this._input.length){`;
                result += "this._tape[this._tapePointer]=this._input[this._input.length-1];";
                result += "}";
            }
        } else {
            result += `this._tape[this._tapePointer]=${this._settings.eof === 'return0' ? 0 : this._settings.cellSize - 1};`;
        }
        result += "}else{";
        result += `this._tape[this._tapePointer]=this._input[this._inputPointer${plusValueMinusOne}];`;
        result += "}";
        result += `this._inputPointer+=${instr.repeat};`;
        return result;
    }
}
