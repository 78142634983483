import { Model, ModelCreate } from "./indexed-db/store";

export type Settings = {
    execution: ExecSettings,
    display: DisplaySettings,
}

export type ExecSettings = {
    eof: 'return0' | 'return-1' | 'noChange',
    tapeSize: number,
    cellSize: number,
    signedCell: boolean,
    tapeOverflow: 'wrap' | 'ignore' | 'throw',
    cellOverflow: 'wrap' | 'ignore' | 'throw',
    newLine: 'n' | 'rn' | 'r',
    characterSet: 'ascii' | 'utf-8' | 'utf-16',
};

export type DisplaySettings = {
    showExtraInfo: boolean,
};

export type BfFileCreate = ModelCreate & {
    title: string,
    content: {
        sourceCode: string,
        inputValue: string,
        settings: Settings,
    },
};

export type BfFile = Model & BfFileCreate;

function initialize<T>(obj: T | undefined, template: T): T {
    if (obj == null) {
        return template;
    }
    if (template == null || typeof template !== 'object') {
        return obj;
    }
    const objAny: any = obj;
    for (const [key, value] of Object.entries(template)) {
        objAny[key] = initialize(objAny[key], value);
    }
    return objAny;
}

export function initFile(file?: BfFile): BfFileCreate {
    return initialize(file, createFile());
}

export function createFile(title?: string): BfFileCreate {
    return {
        title: title ?? '',
        content: {
            sourceCode: '',
            inputValue: '',
            settings: {
                execution: {
                    eof: "return0",
                    tapeSize: 32768,
                    cellSize: 256,
                    signedCell: false,
                    tapeOverflow: "wrap",
                    cellOverflow: "wrap",
                    newLine: "n",
                    characterSet: "ascii",
                },
                display: {
                    showExtraInfo: false,
                },
            },
        },
    };
}
