<template>
  <v-dialog
      v-model="vm.editDialog"
      width="auto"
      min-width="250"
      max-width="400"
      persistent>
    <v-card
        :title="`Edit Cell ${ vm.editCell }`">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="vm.editDezValue"
              label="Dezimal Value"
              density="comfortable"
              hide-details
              @change="vm.setEditCellValueByDezValue($event.target.value)">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="vm.editHexValue"
              label="Hexadezimal Value"
              density="comfortable"
              hide-details
              @change="vm.setEditCellValueByHexValue($event.target.value)">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
                class="output-font-input output-font-span"
                :items="vm.editCharItems"
                v-model="vm.editCharDisplayItem"
                v-model:search="vm.editCharSearch"
                label="Character"
                item-title="search"
                density="comfortable"
                no-data-text="Enter a character"
                no-filter
                hide-details
                auto-select-first
                return-object
                @update:model-value="vm.setEditCellValueByCharItem($event)">
              <template v-slot:selection="data">
                <span class="mr-1 output-font">{{ data.item.raw.search }}</span>
                <span v-if="data.item.raw.data && data.item.raw.data.length >= 2">
                  <span :class="['ml-1', 'character-data', i != data.item.raw.index ? 'other-charachter-data' : undefined]" v-for="(d, i) in data.item.raw.data" :key="i">
                      {{ d }}
                  </span>
                </span>
              </template>
              <template v-slot:item="data">
                <v-list-item v-bind="data.props" role="option" :title="undefined">
                  <v-list-item-title>
                    <span class="mr-1 output-font">{{ data.item.raw.search }}</span>
                    <span class="text-error no-charachter-data" v-if="!data.item.raw.data">
                      Cannot be printed
                    </span>
                    <span v-if="data.item.raw.data && data.item.raw.data.length >= 2">
                      <span :class="['ml-1', 'character-data', i != data.item.raw.index ? 'other-charachter-data' : undefined]" v-for="(d, i) in data.item.raw.data" :key="i">
                          {{ d }}
                      </span>
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="vm.editSetAsActiveCell"
              label="Active Cell"
              density="comfortable"
              :disabled="!vm.editCanChangeActiveCell"
              hide-details>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-btn class="ms-auto text-none" @click="vm.closeEditDialog()">Cancel</v-btn>
        <v-btn class="text-none" @click="save()">Save</v-btn>
      </template>
    </v-card>
  </v-dialog>
  <v-sheet class="w-100 pa-4 my-2" color="grey-lighten-3" rounded>
    <v-label>Memory</v-label>
    <v-row>
      <v-col>
        <div class="cell-tape d-flex flex-row align-stretch">
          <v-sheet
              v-for="i in vm.cellsOnPage"
              :key="i"
              :ref="setCellRef(i - 1)"
              class="cell"
              :color="vm.activeCell === (vm.firstDisplayedCell + i - 1) ? 'red-darken-3' : 'grey-lighten-1'"
              rounded>
            <v-container class="cell-container">
              <v-row class="d-flex justify-space-between align-center">
                <h5>Cell {{ vm.firstDisplayedCell + i - 1 }}</h5>
                <v-btn
                    class="ml-2"
                    icon="mdi-pencil"
                    size="small"
                    density="compact"
                    variant="text"
                    :disabled="vm.readonly"
                    @click="vm.openEditDialog(vm.firstDisplayedCell + i - 1)"
                    rounded>
                </v-btn>
              </v-row>
              <v-row>
                <bf-text-info :model-value="cellInfo(i)"></bf-text-info>
              </v-row>
            </v-container>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="auto" class="memory-text-info">
        <bf-text-info :model-value="currentCellTextInfo"></bf-text-info>
      </v-col>
      <v-col class="d-flex justify-center ga-4">
        <!-- Scroll To Active Cell -->
        <v-btn
            icon="mdi-vector-point-select"
            :disabled="vm.activeCell == null"
            @click="vm.scrollToActiveCell(cellRefs)"
            rounded>
        </v-btn>
        <bf-pagination
            v-model="vm.page"
            :item-count="vm.tapeSize"
            v-model:page-size="vm.pageSize"
            :maxPageSize="1024"
            @items-on-page="vm.cellsOnPage = $event"
            @first-item-on-page-index="vm.firstDisplayedCell = $event">
        </bf-pagination>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import BfMemoryViewerViewModel from './bf-memory-viewer.vue.model';
import { IntegerArray } from '@/utils/brainfuck-compiler/brainfuck-worker-controller';
import { StringConverter } from '@/utils/converter/string-converter';
import { TextInfoEntry } from '../bf-text-info/bf-text-info.vue';

export default defineComponent({
  name: 'bf-memory-viewer',
  props: {
    tape: { type: Object as PropType<IntegerArray>, required: false },
    tapeSize: { type: Number, required: true },
    cellSize: { type: Number, required: true },
    signedCell: { type: Boolean, required: true },
    activeCell: { type: Number as PropType<number | undefined>, default: undefined },
    readonly: { type: Boolean, default: false },
    showTextInfo: { type: Boolean, default: false },
    stringConverter: { type: Object as PropType<StringConverter>, default: undefined },
  },
  emits: [ 'saved' ],
  data() {
    return {
      vm: new BfMemoryViewerViewModel(),
      cellRefs: [] as any[],
    }
  },
  computed: {
    currentCellTextInfo(): TextInfoEntry[] {
      if (!this.showTextInfo) {
        return [];
      }
      return [{ label: 'Current Cell', value: this.activeCell ?? 'None' }];
    },
  },
  watch: {
    tape() {
      this.vm.tape = this.tape;
    },
    activeCell() {
      this.vm.activeCell = this.activeCell
    },
    readonly() {
      this.vm.readonly = this.readonly;
    },
    stringConverter() {
      this.vm.stringConverter = this.stringConverter;
    },
  },
  async beforeMount() {
    await this.vm.initialize();
    this.vm.tape = this.tape;
    this.vm.tapeSize = this.tapeSize;
    this.vm.cellSize = this.cellSize;
    this.vm.signedCell = this.signedCell;
    this.vm.activeCell = this.activeCell;
    this.vm.readonly = this.readonly;
    this.vm.stringConverter = this.stringConverter;
  },
  methods: {
    setCellRef(index: number) {
      return (el: any) => this.cellRefs[index] = el;
    },
    save() {
      const change = this.vm.saveEditDialog();
      this.$emit('saved', change);
    },
    cellInfo(i: number): TextInfoEntry[] {
      return [
        { label: 'Dez', value: this.vm.getCellDisplayDez(this.vm.firstDisplayedCell + i - 1) },
        { label: 'Hex', value: this.vm.getCellDisplayHex(this.vm.firstDisplayedCell + i - 1) },
        { label: 'Char', value: this.vm.getCellDisplayChar(this.vm.firstDisplayedCell + i - 1), class: 'output-font-text-info' },
      ];
    }
  }
})
</script>

<style>

  .output-font-input input {
    font-family: monospace;
  }

  .output-font-span span {
    font-family: monospace;
  }

  .info-entry.output-font-text-info .info-value {
    font-family: monospace;
  }

</style>

<style scoped>

  .cell-tape {
    overflow: auto !important;
  }

  .cell {
    margin: 2px;
    padding: 4px;
    min-width: fit-content;
    white-space: nowrap;
  }

  .cell-container {
    min-width: 75px;
  }

  table {
    border-spacing: 4px 0px;
  }

  .character-data {
    color: gray;
  }

  .other-charachter-data, .no-charachter-data {
    font-size: 0.75em;
  }

  @media screen and (min-width: 960px) {
    .memory-text-info {
      position: absolute;
    }
  }

</style>
