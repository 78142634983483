
export class Timer {

    private static readonly UPDATE_TIMER = 10;

    private _beforPauseTime: number = 0;
    private _continuedAtTime?: number;
    private _time?: number;
    private _updateInterval?: number;

    public get time() {
        return this._time;
    }

    public get isReset() {
        return this._time == null;
    }

    public get isStopped() {
        return this._time != null && this._continuedAtTime != null;
    }

    public get isRunning() {
        return this._continuedAtTime != null;
    }

    public reset() {
        this.registerRegualrUpdates(false);
        this._beforPauseTime = 0;
        this._continuedAtTime = undefined;
        this._time = undefined;
    }

    public start() {
        this.reset();
        this.continue();
    }

    public continue() {
        this._continuedAtTime ??= performance.now();
        this.registerRegualrUpdates(true);
        this.updateTime();
    }

    public stop() {
        if (this._continuedAtTime != null) {
            this._beforPauseTime += performance.now() - this._continuedAtTime;
            this._continuedAtTime = undefined;
        }
        this.registerRegualrUpdates(false);
        this.updateTime();
        return this._time;
    }

    private updateTime() {
        this._time = this._beforPauseTime + (this._continuedAtTime == null ? 0 : performance.now() - this._continuedAtTime);
    }

    private registerRegualrUpdates(register: boolean) {
        if (!register) {
            clearInterval(this._updateInterval);
            this._updateInterval = undefined;
            return;
        }
        if (register && this._updateInterval) {
            return;
        }
        this._updateInterval = window.setInterval(() => this.updateTime(), 1000 / Timer.UPDATE_TIMER);
    }
}
