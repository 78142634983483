import { EditorView, basicSetup } from "codemirror";
import { EditorState, Extension } from '@codemirror/state';
import { ViewUpdate } from '@codemirror/view';
import { indentMore, indentLess } from '@codemirror/commands'
import { markRaw } from "vue";

export default class CodeMirrorEditor {

    private _view: EditorView;
    private _source: string = '';
    private _changeListeners: ((source: string) => void)[] = []

    public get view(): EditorView {
        return this._view;
    }

    public get source(): string {
        return this._source;
    }

    public set source(value: string) {
        if (!this._view) {
            throw new Error('Editor not yet created');
        }
        this._view.dispatch({ changes: {from: 0, to: this._view.state.doc.length, insert: value }});
    }

    constructor(id: string, height: string, extensions: Extension[]) {
        const div = document.querySelector("#" + id);
        if (!div) {
            throw new Error('Editor mount element not found');
        }
        const updateListener = EditorView.updateListener.of((v: ViewUpdate) => {
            if (!v.docChanged) {
                return;
            }
            this._source = v.state.doc.toString();
            this._changeListeners.forEach(callback => callback(this._source));
        });
        const theme = EditorView.theme({
            "&": {
                font: "monospace",
            },
            "&.cm-focused": {
                outline: "none",
            },
            "&.cm-editor": {
                height: height,
            },
            "&.cm-scroller": {
                overflow: "auto",
            },
        });

        const state = EditorState.create({
            extensions: [
                basicSetup,
                updateListener,
                theme,
                extensions,
            ],
        });
        this._view = markRaw(new EditorView({
            parent: div,
            state: state,
            scrollTo: EditorView.scrollIntoView(0),
        }));
    }

    public addChangeListener(callback: (source: string) => void) {
        this._changeListeners.push(callback);
    }

    public removeChangeListener(callback: (source: string) => void) {
        const index = this._changeListeners.indexOf(callback);
        if (index > -1) {
            this._changeListeners.splice(index, 1);
        }
    }

    public scrollTo(index: number) {
        this._view.dispatch({
            effects: EditorView.scrollIntoView(index),
        });
    }

    public indentSelection(indent: 'more' | 'less') {
        const indentFunc = indent === 'more' ? indentMore : indentLess;
        indentFunc({
            state: this._view.state,
            dispatch: transaction => this._view.update([transaction]),
        });
    }
}
