<template>
  <table>
    <tr v-for="item of modelValue" :key="item.label" :class="['info-entry', item.class]">
      <td class="info-label pr-2">
        <v-label class="value-label">{{ item.label }}</v-label>
      </td>
      <td ref="textInfoValue" :class="{ 'info-value': true, 'text-right': !item.combinedValueAndUnit }" :colspan="item.combinedValueAndUnit ? 2 : undefined">
        <slot :name="`value-${item.id}`"><bf-grow-only>{{ item.value }}</bf-grow-only></slot>
      </td>
      <td v-if="item.unit && !item.combinedValueAndUnit" class="info-unit">
        <slot :name="`unit-${item.id}`">{{ item.unit }}</slot>
      </td>
    </tr>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export type TextInfoEntry = { id?: string, label: string, value?: string | number | undefined, unit?: string, combinedValueAndUnit?: boolean, class?: string };

export default defineComponent({
  name: 'bf-text-info',
  props: {
    modelValue: { type: Array as PropType<TextInfoEntry[]>, default: () => [] },
  },
})
</script>

<style scoped>

  .value-label {
    font-size: 0.75em;
  }

  table {
    border-spacing: 4px 0px;
  }

  .info-value {
    width: 1px;
    white-space: nowrap;
  }

</style>
